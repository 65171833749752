import { useWeb3React } from '@web3-react/core'
import { Contract, ethers } from 'ethers'
import { useEffect, useState } from 'react'
import NftAbi from '../constants/nft.abi.json'

export const useNftContractSigner = () => {
    const [contract, setContract] = useState<Contract | null>(null)
    const { library } = useWeb3React()
    let contractAddress = ethers.utils.getAddress(process.env.REACT_APP_NFT_ADDRESS + '')

    useEffect(() => {
        let signer = library && library.getSigner()
        signer && contractAddress && library && setContract(new Contract(contractAddress, NftAbi, signer))
    }, [library, contractAddress])

    return contract
}
export const useNftContract = () => {
    const [contract, setContract] = useState<Contract | null>(null)
    const { library } = useWeb3React()
    let contractAddress = ethers.utils.getAddress(process.env.REACT_APP_NFT_ADDRESS + '')

    useEffect(() => {
        library && contractAddress && library && setContract(new Contract(contractAddress, NftAbi, library))
    }, [library, contractAddress])

    return contract
}
