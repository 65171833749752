import { Button, Col, Input, Layout, Row, Space, Typography } from "antd";
import { ArrowRightOutlined } from '@ant-design/icons';
const { Text } = Typography

const Footer = () => {
    return <Layout.Footer style={{
        marginTop: 128,
        marginBottom:64
    }}>
        <Row>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 18 }} lg={{ span: 18 }} span={18}>
                <Row>
                    <Col className="footer-col" span={8}>
                        <Button type='text'>
                            <Text strong>About</Text>
                        </Button>
                    </Col>
                    <Col className="footer-col" span={8}>
                        <Button type='text'>
                            <Text strong>What we do</Text>
                        </Button>
                    </Col>
                    <Col className="footer-col" span={8}>
                        <Button type='text'>
                            <Text strong>Jobs</Text>
                        </Button>
                    </Col>
                    <Col className="footer-col" span={8}>
                        <Button type='text'>
                            <Text strong>Project</Text>
                        </Button>
                    </Col>
                    <Col className="footer-col" span={8}>
                        <Button type='text'>
                            <Text strong>Press</Text>
                        </Button>
                    </Col>
                    <Col className="footer-col" span={8}>
                        <Button type='text'>
                            <Text strong>Download</Text>
                        </Button>
                    </Col>
                </Row>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} className="footer-search">
                <Space direction='vertical' style={{
                    width:'100%'
                }}>
                    <Text>
                        Join our community 🔥
                    </Text>
                    <Input placeholder="Enter your email" suffix={<Button type='primary' shape='circle'>
                        <ArrowRightOutlined />
                    </Button>} />
                </Space>
            </Col>
        </Row>
    </Layout.Footer>
}

export default Footer;