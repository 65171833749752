import axios from "axios"

const errorHandler = (error: any) => {
  const { request, response } = error;
  if (response) {
    const { message } = response.data;
    const status = response.status;
    return {
      message,
      status,
    };
  } else if (request) {
    //request sent but no response received
    return {
      message: "server time out",
      status: 503,
    };
  } else {
    // Something happened in setting up the request that triggered an Error
    return { message: "opps! something went wrong while setting up request" };
  }
};
const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT + '',
  timeout: 60 * 1000
});

Axios.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {
  return Promise.reject(errorHandler(error));
});

export default Axios;