import { useMemo } from 'react'
import { moment } from '../../../helpers/time'
import { RoomBooked } from "../../../Types/Room"
import { groupBy } from "lodash"

/**
 * 
 * @param room 
 * @param checkIn Number
 * @param checkOut Number
 */
const useNftAmount = (rooms: any, checkIn: any, checkOut: any) => {
    checkIn = moment(checkIn)
    checkOut = moment(checkOut)
    let time = moment(checkIn)

    const night = useMemo(() => {
        let count = 0
        while (time.isSameOrAfter(checkIn) && time.isBefore(checkOut)) {
            count++
            time.add(1, 'days')
        }
        return count
    }, [])
    return rooms.reduce((ret: any, room: any) => {
        return ret + room.amount * night
    }, 0)
}

const _getNftPrice = (room: any) => {
    let adultPerNft = Number(process.env.REACT_APP_ADULT_PER_NFT || 2)
    return Math.round(room.maxAdult / adultPerNft)
}
const _getNumberNight = (checkIn: number, checkOut: number) => {
    let momentCheckIn = moment(checkIn)
    let momentCheckOut = moment(checkOut)
    let time = moment(checkIn)

    let count = 0
    while (time.isSameOrAfter(momentCheckIn) && time.isBefore(momentCheckOut)) {
        count++
        time.add(1, 'days')
    }
    return count
}
export const useNftDetail = (rooms: RoomBooked[], checkIn: number, checkOut: number) => {
    const priceDetails = useMemo(() => {
        checkIn = moment(checkIn).set({ "hour": process.env.REACT_APP_CHECKIN as any, "minute": 0, "second": 0, "millisecond": 0 }).valueOf();
        checkOut = moment(checkOut).set({ "hour": process.env.REACT_APP_CHECKOUT as any, "minute": 0, "second": 0, "millisecond": 0 }).valueOf();
        if (!process.env.REACT_APP_NFT) {
            return []
        }
        let price: any[] = []

        let numberNight: number = _getNumberNight(checkIn, checkOut)

        rooms.forEach(room => {

            let pricePerNight = _getNftPrice(room)

            price.push({
                room,
                price: pricePerNight * room.amount * numberNight,
                label: '',
                start: moment(checkIn).format('MMM DD,YYYY'),
                end: moment(checkOut).format('MMM DD,YYYY'),
                night: numberNight,
                pricePerNight
            })
        });
        return price.flat()
    }, [rooms, checkIn, checkOut])
    return {
        totalPrice: priceDetails.reduce((ret: any, p: any) => {
            return ret + p.price
        }, 0),
        group: groupBy(priceDetails, (r) => {
            return JSON.stringify({ id: r.room._id, name: r.room.name, amount: r.room.amount })
        })
    }
}

export default useNftAmount;