import { useLocation } from "react-router-dom";


export const useQuery = () => {
    const search = useLocation().search;
    const query: any = new URLSearchParams(search)
    const params: any = {}

    for (const [key, value] of query.entries()) {
        params[key] = value
    }

    return { params }
}