
const encodeGetParams = (p: any) =>
    Object.entries(p).map(kv => kv.map((value: any) => encodeURIComponent(value)).join("=")).join("&");

export const getImageUrl = (path: any) => `${process.env.REACT_APP_API_ENDPOINT}/${path}`.replace('/public', '')


export const queryStringify = (path: any, params: any) => path + "?" + encodeGetParams(params);


export function insertUrlParam(key: string, value: any) {
    if (window.history.pushState) {
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set(key, value);
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({ path: newurl }, '', newurl);
    }
}

// to remove the specific key
export function removeUrlParameter(paramKey: string) {
    const url = window.location.href
    console.log("url", url)
    var r = new URL(url)
    r.searchParams.delete(paramKey)
    const newUrl = r.href
    console.log("r.href", newUrl)
    window.history.pushState({ path: newUrl }, '', newUrl)
}

export function isValidHttpUrl(val: string) {
    let url;

    try {
        url = new URL(val);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}
