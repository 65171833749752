import { useWeb3React } from "@web3-react/core"
import { useCallback, useEffect } from "react"
import * as Consumers from '../consumers'
import { chainPublisher } from '../workers/chain'
const Worker = () => {
    const { account } = useWeb3React()

    const handleSub = useCallback(async (account: string) => {
        let events = [Consumers.default.HeronNft(account), Consumers.default.HeronVoucher(account)]
        for (let index = 0; index < events.length; index++) {
            chainPublisher.subscribe(events[index])
        }
        return null
    }, [])
    const handleUnSub = useCallback(() => {
        return chainPublisher.unSubscribeAll(false)
    }, [])
    useEffect(() => {
        account && console.info('sub', account)
        account && setTimeout(() => {
            handleSub(account)
        }, 1000);

        return () => {
            console.info('unsub', account)
            handleUnSub()
        }
    }, [account, handleUnSub, handleSub])
    return null
}
export default Worker