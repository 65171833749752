import { combineReducers } from "redux";
import resort from './resort'
import room from './room'
import reservation from './reservation'
import wallet from './wallet'
import swap from "./swap";
import transfer from "./transfer";
import claim from "./claim";

export default combineReducers({
    resort,
    room,
    reservation,
    wallet,
    swap,
    transfer,
    claim
});