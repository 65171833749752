import { Log } from "@ethersproject/providers"
import { useWeb3React } from "@web3-react/core"
import { notification } from "antd"
import { BigNumber, ethers } from "ethers"
import { useCallback, useEffect, useRef, useState } from "react"
import useVoucherContract from "../../../Hook/useVoucherContract"
import { VOUCHER_PRIVATE, VOUCHER_STATUS } from "../../../Types/Voucher"
import { chainPublisher, provider } from '../../../workers/chain'
import { localStorageKey } from '../../../consumers/heron-voucher'
const VoucherAbi = [
    "event Deposit(address indexed _sender,uint256[] _ids, bytes32 _messageHash)",
    "event Claim(uint256[] _ids, bytes32 _messageHash)",
    "event Withdraw(uint256[] _ids, bytes32 _messageHash)"
];

const voucherInterface = new ethers.utils.Interface(VoucherAbi)
const logsToEventMap = (logs: Log[]) => {
    return logs.reduce((ret: Map<any, any>, log: Log) => {
        let e = voucherInterface.parseLog(log)
        ret.set(e.args._messageHash, e)
        return ret
    }, new Map())
}
const mergeLogsDepositAndLogsClaim = (logsDeposit: Log[], logsClaim: Log[], logsWithdraw: Log[], owner: string): VOUCHER_PRIVATE[] => {
    let eventClaimMap = logsToEventMap(logsClaim)
    let eventDepositMap = logsToEventMap(logsDeposit)
    let eventWithdrawtMap = logsToEventMap(logsWithdraw)
    let ret: any = []
    let prefix = owner.substr(0, 6) + '...' + owner.substr(-4)
    let i = 1
    eventDepositMap.forEach((v, k) => {
        if (eventClaimMap.has(k)) {
            // Voucher have been claimed
            ret.push({
                _messageHash: v.args._messageHash,
                _ids: v.args._ids.map((i: BigNumber) => i.toNumber()),
                _name: `${prefix}_${i++}`,
                _status: VOUCHER_STATUS.CLAIMED
            })

            return
        }
        if (eventWithdrawtMap.has(k)) {
            // Voucher have been claimed
            ret.push({
                _messageHash: v.args._messageHash,
                _isWithdraw: true,
                _ids: v.args._ids.map((i: BigNumber) => i.toNumber()),
                _name: `${prefix}_${i++}`,
                _status: VOUCHER_STATUS.WITHDRAWN
            })

            return
        }
        ret.push({
            _messageHash: v.args._messageHash,
            _ids: v.args._ids.map((i: BigNumber) => i.toNumber()),
            _name: `${prefix}_${i++}`,
            _status: VOUCHER_STATUS.CREATED
        })
    })
    return ret;
}
const useRetrieveVoucher = () => {
    const { account } = useWeb3React()
    const [loading, setLoading] = useState(false)
    const timeout = useRef<any>()
    const [vouchers, setVouchers] = useState<VOUCHER_PRIVATE[]>([])
    const dic = useRef<{ [key: string]: VOUCHER_PRIVATE }>({})
    useEffect(() => {
        if (vouchers.length === 0) {
            dic.current = {}
        }
        vouchers.forEach(v => {
            dic.current[v._messageHash] = v
        })
    }, [vouchers])
    useEffect(() => {
        if (account) {
            setLoading(true)
            timeout.current = setInterval(async () => {
                const listVoucher = JSON.parse(await chainPublisher.getState(localStorageKey(account)))
                if (listVoucher?.length >= 0) {
                    setLoading(false)
                    listVoucher.forEach((v: VOUCHER_PRIVATE) => {
                        if (dic.current[v._messageHash]) {
                            v._qr = dic.current[v._messageHash]._qr
                        }
                    })
                    setVouchers(listVoucher)
                } else {
                    console.info('loading listVoucher....')
                }
            }, 5000)
        } else {
            setVouchers([])
            clearInterval(timeout.current)
        }
        return () => {
            setVouchers([])
            clearInterval(timeout.current)
        }

    }, [account])
    return { vouchers, loading, setVouchers }
}
export default useRetrieveVoucher