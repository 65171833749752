import { useWeb3React } from "@web3-react/core"
import { notification } from "antd"
import { ethers } from "ethers"
import { useCallback, useState } from "react"
import { useLoadingModal } from "../../../Hook/useLoading"
import useNewShortLink from "./useShortLink"

export const useShowQR = (messageHash: string) => {
    const { library, account } = useWeb3React()
    const { showLoading, hideLoading } = useLoadingModal()
    const [qrValue, setQrValue] = useState('')
    const showQR = useCallback(async () => {
        if (!messageHash || !library || !account) return notification.error({ message: "Please connect to your wallet" })
        try {
            showLoading()
            let signature = await library?.getSigner().signMessage(ethers.utils.arrayify(messageHash))
            setQrValue(`${signature}-${messageHash}`)
            hideLoading()
            return signature
        } catch (error: any) {
            hideLoading()
            return notification.error({ message: error?.message || error.toString() })
        }
    }, [library, messageHash, account, hideLoading, showLoading])

    return { qrValue, showQR }
}
/**
 * 
 * @returns async function on signe message
 * return null if have some error
 * return string if sign success
 */
export const useCallBackShowQr = () => {
    const { library, account } = useWeb3React()
    const { showLoading, hideLoading } = useLoadingModal()
    const { newShortLink } = useNewShortLink()
    const showQR = useCallback(async (messageHash: string) => {
        if (!messageHash || !library || !account) {
            notification.error({ message: "Please connect to your wallet" })
            return null
        }
        try {
            showLoading()
            let signature = await library?.getSigner().signMessage(ethers.utils.arrayify(messageHash))
            hideLoading()
            let longUrl = `${process.env.REACT_APP_NFT_INTERFACE_BASE_URL}?voucher=${signature}-${messageHash}`
            let sortUrl = await newShortLink(longUrl)
            return sortUrl
        } catch (error: any) {
            hideLoading()
            notification.error({ message: error?.message || error.toString() })
            return null
        }
    }, [library, account, hideLoading, showLoading])
    return { showQR }
}