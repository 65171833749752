import { Space, Typography } from "antd"
import { useMemo } from "react"
import { InfoItem } from "../../../components/InfoItem"
import Timeline from "../Timeline"
import { formatMoney } from "../../../helpers/number"
const { Text } = Typography
type OrderInfoWrapperProps = {
    priceDetails: any,
    nftDetails: any
}
const OrderInfoWrapper = (props: OrderInfoWrapperProps) => {
    const isNft = useMemo(() => {
        return process.env.REACT_APP_NFT
    }, [])
    if (isNft) return <OrderInfoNft {...props} />
    return <OrderInfoBasic  {...props} />

}
const OrderInfoNft = (props: OrderInfoWrapperProps) => {
    return <Space direction='vertical' style={{
        width: '100%',
    }}>
        <Timeline nftDetails={props.nftDetails} />

        <div className="heron-order-gateway-reservation-total">
            <InfoItem strong={true} label="Total" >
                <Text strong>{`${props?.nftDetails?.totalPrice} NFT`}</Text>
            </InfoItem>
        </div>
    </Space>
}
const OrderInfoBasic = (props: OrderInfoWrapperProps) => {

    return <Space direction='vertical' style={{
        width: '100%',
    }}>
        <Timeline priceDetail={props.priceDetails} />

        <div className="heron-order-gateway-reservation-total">
            <InfoItem strong={true} label="Total" >
                <Text strong>{formatMoney(props?.priceDetails?.totalPrice)}</Text>
            </InfoItem>
        </div>
    </Space>
}

export default OrderInfoWrapper