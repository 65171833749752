
import { Room } from '../Types/Room';
import { FETCH_ROOM_SUCCESS, FETCH_ROOM_START } from '../actions/types'
const initialState: {
    data: Room[], loading: boolean
} = {
    data: [],
    loading: false
};
export default function (state = initialState, action: { type: any; payload: any; }) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_ROOM_START:
            return { ...state, loading: true }
        case FETCH_ROOM_SUCCESS:
            return { data: payload, loading: false };
        default:
            return state;
    }
}