import { useWeb3React } from "@web3-react/core"
import { notification } from "antd"
import { ethers } from "ethers"
import { useCallback } from "react"
import { validateConnectWallet } from "../../../helpers/validator"
import { useLoadingModal } from "../../../Hook/useLoading"
import { useVoucherContractSigner } from "../../../Hook/useVoucherContract"

const useWithdraw = () => {
    const { showLoading, hideLoading } = useLoadingModal()

    const { library, account } = useWeb3React()

    const voucherContract = useVoucherContractSigner()

    const withdraw = useCallback(async (_messageHash: string) => {
        if (!voucherContract) return notification.error({
            message: "Not founc vouncer contract"
        })
        try {
            const [error] = validateConnectWallet(library, account)
            if (error) return notification.error({
                message: error
            })
            showLoading()
            let _signature = await library?.getSigner().signMessage(ethers.utils.arrayify(_messageHash))
            let tx = await voucherContract.withdraw(_signature, _messageHash)
            await tx.wait(1)
            hideLoading()
        } catch (error: any) {
            notification.error({
                message: error?.message || error.toString()
            })
            hideLoading()
        }

    }, [voucherContract, showLoading, hideLoading, account, library])
    return { withdraw }
}

export default useWithdraw;