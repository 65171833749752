import { JsonRpcProvider, Log } from "@ethersproject/providers";
import { BigNumber, ethers } from "ethers";
import { VOUCHER_PRIVATE, VOUCHER_STATUS } from "../Types/Voucher";

const VoucherAbi = [
    "event Deposit(address indexed _sender,uint256[] _ids, bytes32 _messageHash)",
    "event Claim(uint256[] _ids, bytes32 _messageHash)",
    "event Withdraw(uint256[] _ids, bytes32 _messageHash)"
];
let itf = new ethers.utils.Interface(VoucherAbi)
const voucherAddress = ethers.utils.getAddress(process.env.REACT_APP_VOUCHER_ADDRESS + '')

const voucherInterface = new ethers.utils.Interface(VoucherAbi)

const voucherContract = new ethers.Contract(voucherAddress, VoucherAbi)

const logsToEventMap = (logs: Log[]) => {
    return logs.reduce((ret: Map<any, any>, log: Log) => {
        let e = voucherInterface.parseLog(log)
        ret.set(e.args._messageHash, e)
        return ret
    }, new Map())
}
const mergeLogsDepositAndLogsClaim = (logsDeposit: Log[], logsClaim: Log[], logsWithdraw: Log[], owner: string): VOUCHER_PRIVATE[] => {
    let eventClaimMap = logsToEventMap(logsClaim)
    let eventDepositMap = logsToEventMap(logsDeposit)
    let eventWithdrawtMap = logsToEventMap(logsWithdraw)
    let ret: any = []
    let prefix = owner.substr(0, 6) + '...' + owner.substr(-4)
    let i = 1
    eventDepositMap.forEach((v, k) => {
        if (eventClaimMap.has(k)) {
            // Voucher have been claimed
            ret.push({
                _messageHash: v.args._messageHash,
                _ids: v.args._ids.map((i: BigNumber) => i.toNumber()),
                _name: `${prefix}_${i++}`,
                _status: VOUCHER_STATUS.CLAIMED
            })

            return
        }
        if (eventWithdrawtMap.has(k)) {
            // Voucher have been claimed
            ret.push({
                _messageHash: v.args._messageHash,
                _isWithdraw: true,
                _ids: v.args._ids.map((i: BigNumber) => i.toNumber()),
                _name: `${prefix}_${i++}`,
                _status: VOUCHER_STATUS.WITHDRAWN
            })

            return
        }
        ret.push({
            _messageHash: v.args._messageHash,
            _ids: v.args._ids.map((i: BigNumber) => i.toNumber()),
            _name: `${prefix}_${i++}`,
            _status: VOUCHER_STATUS.CREATED
        })
    })
    return ret;
}
const extractLogsToMap = (logs: Log[]) => {
    let depositLogs: Log[] = [];
    let claimLogs: Log[] = [];
    let withdrawnLogs: Log[] = [];

    let eventDeposit = new Map();
    let eventClaim = new Map();
    let eventWithdrawn = new Map();

    logs.forEach(log => {
        let e = itf.parseLog(log)
        let topic0 = log.topics[0]
        if (voucherContract.filters.Claim().topics?.includes(topic0)) {

            eventClaim.set(e.args._messageHash, e)
            return claimLogs.push(log)
        }
        if (voucherContract?.filters.Withdraw().topics?.includes(topic0)) {
            eventWithdrawn.set(e.args._messageHash, e)
            return withdrawnLogs.push(log)
        }
        eventDeposit.set(e.args._messageHash, e)
        return depositLogs.push(log)

    })

    return {
        eventDepositMap: eventDeposit,
        eventClaimMap: eventClaim,
        eventWithdrawMap: eventWithdrawn,
        depositLogs,
        claimLogs,
        withdrawnLogs
    }
}
export const localStorageKey = (account: string) => {
    return `vouchers-${account}`
}
const HeronVoucherConsumer = (account: string) => {
    let filterDeposit = voucherContract?.filters.Deposit(ethers.utils.getAddress(account))
    let filterClaim = voucherContract?.filters.Claim()
    let filterWithdraw = voucherContract?.filters.Withdraw()

    let prefix = account.substr(0, 6) + '...' + account.substr(-4)
    let localStorageKey = `vouchers-${account}`
    return {
        key: `voucher-${account}`,
        genesis: 0,
        safeDepth: 8,
        filter: [
            filterDeposit,
            filterClaim,
            filterWithdraw
        ],
        applyLogs: async (storage: any, logs: ethers.providers.Log[], unSafe?: boolean) => {
            if (!logs.length) {
                let nfts = JSON.parse(JSON.parse(localStorage.getItem(localStorageKey) || 'null') || 'null') || []
                await storage.setItem(localStorageKey, JSON.stringify(nfts))
                return
            }
            let { depositLogs, claimLogs, withdrawnLogs } = extractLogsToMap(logs)
            let vouchers: any[] = JSON.parse(JSON.parse(localStorage.getItem(localStorageKey) || 'null') || 'null') || []

            let start = vouchers.length

            depositLogs.forEach((log, i) => {
                let event = itf.parseLog(log)
                let messageHash = event.args._messageHash
                let voucherCreatedIndex = vouchers.findIndex(v => v._messageHash === messageHash);
                if (voucherCreatedIndex !== -1) return console.warn("DEPOSIT: Duplicate", messageHash)
                vouchers.push({
                    _messageHash: event.args._messageHash,
                    _ids: event.args._ids.map((i: BigNumber) => i.toNumber()),
                    _name: `${prefix}_${++start}`,
                    _status: VOUCHER_STATUS.CREATED
                })

            })
            claimLogs.forEach((log) => {
                let event = itf.parseLog(log)
                let messageHash = event.args._messageHash
                let voucherCreatedIndex = vouchers.findIndex(v => v._messageHash === messageHash);
                if (voucherCreatedIndex === -1) return console.warn("CLAIM: Not found voucher", messageHash)
                vouchers[voucherCreatedIndex]._status = VOUCHER_STATUS.CLAIMED
            })
            withdrawnLogs.forEach(log => {
                let event = itf.parseLog(log)
                let messageHash = event.args._messageHash
                let voucherCreatedIndex = vouchers.findIndex(v => v._messageHash === messageHash);
                if (voucherCreatedIndex === -1) return console.warn("WITHDRAWN: Not found voucher", messageHash)
                vouchers[voucherCreatedIndex]._status = VOUCHER_STATUS.WITHDRAWN
            })
            await storage.setItem(localStorageKey, JSON.stringify(vouchers))
        }

    }
}
export default HeronVoucherConsumer