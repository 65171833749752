import { Image } from "antd"
import { useState } from "react";

const ImageCollection = (props: { imgs: any[], style?: any, className?: string }) => {
    const [visible, setVisible] = useState(false);
    return <>
        <Image preview={{ visible: false }} onClick={() => setVisible(true)} className={props.className} style={props.style} src={props.imgs[0]} />

        <div style={{ display: 'none' }}>
            <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis) }}>
                {
                    props.imgs.map(img => (
                        <Image key={img} src={img} />
                    ))
                }
            </Image.PreviewGroup>
        </div>
    </>
}
export default ImageCollection