import { Col, Image, Row, Select, Space, Typography } from "antd"
import { useDispatch, useSelector } from "react-redux";
import { max } from 'lodash'
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from 'react-i18next'

import { getImageUrl } from "../../helpers/url";
import { formatMoney } from "../../helpers/number";
import product from '../../product.png'

import { changeRoom } from '../../actions/reservation'
import ImageCollection from "../../components/ImageCollection";
import { RoomBooked } from "../../Types/Room";
import BoxPrice from "./BoxPrice";

const { Title, Text, Paragraph } = Typography;
const { Option } = Select
const CardRoom = ({ room }: any) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const rooms: RoomBooked[] = useSelector((state: any) => state.reservation?.rooms || [])

    const dic = useRef({
        amount: 0
    })

    const onChangeAmount = useCallback((amount) => {
        dic.current.amount = amount
        let newRooms = []
        if (amount == 0) {
            // remove room
            newRooms = rooms.filter((r: any) => r._id != room._id)
        } else {
            // add or update room
            newRooms = [...rooms]
            let isNew = true
            newRooms.forEach(r => {
                if (r._id == room._id) {
                    r.amount = amount
                    isNew = false
                }
            })
            if (isNew) newRooms.push({ ...room, amount })
        }
        dispatch(changeRoom(newRooms))
    }, [rooms, room])

    const defaultValue = useMemo(() => {
        const amount = rooms.find((r: any) => r._id == room._id)?.amount || 0
        dic.current.amount = amount
        return amount
    }, [rooms])

    const priceSale = useMemo(() => {
        return Number(room.priceToday - room.priceToday * room.saleToday / 100)
    }, [room])
    return <div className="container-card">
        <div className="container-card-left">
            <Space className="container-card-left-content" direction='vertical' size={16}>
                <ImageCollection style={{
                    width: '100%',
                    objectFit: 'contain'
                }} className="card-avatar" imgs={room.imgs?.length ?
                    room.imgs.map((img: string) => getImageUrl(img)) : [product]
                } />
                <Row>
                    <Col span={12}>
                        <Title
                            ellipsis={{
                                tooltip: room?.name
                            }}
                            className="card-room-title"
                            style={{
                                color: '#141416',
                                paddingLeft: 16
                            }}
                            level={5}
                        >
                            {room?.name}
                        </Title>
                        <Text className="card-room-recommend">{`Recommend: ${room.maxAdult} Adults`}</Text>
                    </Col>
                    <BoxPrice room={room} />
                </Row >
            </Space >
            {
                process.env.REACT_APP_NFT ?
                    (
                        <Row className="sale-available-obsolute">
                            <Space>

                                {
                                    max([room?.capacity, 0]) < 1 ? <Text strong type='secondary'>{t('common.outOfRoomUppercase')}</Text> : <Text strong>
                                        {t('common.available')} <Text type="success" strong>{max([room?.capacity, 0])}</Text>
                                    </Text>
                                }

                            </Space>
                        </Row>
                    ) :
                    (
                        <Row className="sale-available-obsolute">
                            <Space>
                                {
                                    room.saleToday ? <Text strong>{`${t('common.saleUppercase')} ${room.saleToday}%`}</Text> : null
                                }
                                {
                                    max([room?.capacity, 0]) < 1 ? <Text strong type='secondary'>{t('common.outOfRoomUppercase')}</Text> : <Text strong>
                                        {t('common.available')} <Text type="success" strong>{max([room?.capacity, 0])}</Text>
                                    </Text>
                                }

                            </Space>
                        </Row>
                    )
            }
        </div >
        <div className="container-card-right">
            <div className="description-room" dangerouslySetInnerHTML={{ __html: room.description }} />
        </div>
        <div className="select-amount-sticky">
            <Title level={4} style={{
                color: '#329696'
            }} type='success'>{t('reservation.selectAmount')}</Title>
            <Select defaultValue={defaultValue} style={{ width: 220 }} value={dic.current.amount} onChange={onChangeAmount}>
                {
                    new Array(room.capacity + 1).fill(0).map((e, i) => {
                        i = Number(i)
                        if (i == 0) return <Option key={i} value={i}>{`${i}`}</Option>
                        if (process.env.REACT_APP_NFT) {
                            return <Option key={i} value={i}>{`${i} room (${Math.round(room.maxAdult / 2) * i} NFT)`}</Option>
                        }
                        return (
                            <Option key={i} value={i}>{`${i} room (${formatMoney(Number(room?.priceToday - room?.priceToday * room?.saleToday / 100) * i)} VND)`}</Option>
                        )
                    })
                }
            </Select>
        </div>
    </div >
}

export default CardRoom