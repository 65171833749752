import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useQuery } from "../../../Hook/query"

import { ipn } from '../../../actions/payment'
import { notification } from "antd"

export default () => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')

    const { params } = useQuery()
    const dispatch = useDispatch()

    useEffect(() => {
        if (!process.env.REACT_APP_USE_MOMO) {
            setLoading(false)
        } else {
            dispatch(
                ipn(
                    params,
                    () => {
                        setLoading(false)
                    },
                    (error: any) => {
                        notification.error({
                            message: error
                        })
                        setError(error)
                        setLoading(false)
                    }
                )
            )
        }

    }, [])
    return { loading, error }
}