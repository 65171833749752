import { useMemo } from "react"
import { moment, isReminder } from '../../../helpers/time'
import { groupBy } from "lodash"
import { RoomBooked } from "../../../Types/Room"
const _getPriceBySale = (price: any, sale: any) => {
    return price - price * sale / 100
}
const _getPrice = (room: any, timeline: any, timelineReminder: any) => {
    if (timeline) {
        return _getPriceBySale(timeline.price, timeline.sale)
    }
    if (timelineReminder) {
        return _getPriceBySale(timelineReminder.price, timelineReminder.sale)
    }
    return _getPriceBySale(room.price, room.sale)
}

const usePrice = (rooms: RoomBooked[], checkIn: number, checkOut: number) => {
    checkIn = moment(checkIn).set({ "hour": process.env.CHECKIN as any, "minute": 0, "second": 0, "millisecond": 0 }).valueOf();
    checkOut = moment(checkOut).set({ "hour": process.env.CHECKOUT as any, "minute": 0, "second": 0, "millisecond": 0 }).valueOf();
    let start = moment(checkIn).format('MMM DD,YYYY')
    let end = moment(checkOut).startOf('days')

    const priceDetails = useMemo(() => {
        if (process.env.REACT_APP_NFT) {
            return []
        }
        let price: any[] = []
        rooms.forEach(room => {
            let timelines = room?.timelines?.map((timeline: any) => ({ ...timeline, range: moment.range(timeline.startTime, timeline.endTime) })) || []

            let timelinesNotReminder = timelines.filter(t => !t.reminder)
            let timelinesReminder = timelines.filter(t => t.reminder)

            let time = moment(start, 'MMM DD,YYYY')
            let _price = []

            while (time.isBefore(end)) {
                const timelineFounded = timelinesNotReminder.find((t: any) => t.range.contains(time))
                const timelineReminder = timelinesReminder.find((t: any) => isReminder(t, time))
                let pricePerNight = _getPrice(room, timelineFounded, timelineReminder)
                _price.push({
                    room,
                    price: pricePerNight * room.amount,
                    label: timelineFounded ? timelineFounded.label || "Special Price" : timelineReminder ? timelineReminder.label || "Special Price" : '',
                    start: time.startOf('days').format('MMM DD,YYYY'),
                    end: moment(time).add(1, 'days').format('MMM DD,YYYY'),
                    night: 1
                })
                time.add(1, 'days')
            }

            let ret = _price.reduce((ret: any, p: any, i: any) => {
                if (i == 0) return [p]

                let pre = ret[ret.length - 1]

                if (pre.price == p.price && pre.label == p.label) {
                    pre.end = p.end
                    pre.night = pre.night + p.night
                    ret[ret.length - 1] = pre

                    return ret
                } else {
                    return [...ret, p]
                }
            }, [])

            price.push(ret)
        });

        return price.flat()

    }, [rooms, checkIn, checkOut])

    return {
        totalPrice: priceDetails.reduce((ret: any, p: any) => {
            return ret + p.price * p.night
        }, 0),
        group: groupBy(priceDetails, (r) => {
            return JSON.stringify({ id: r.room._id, name: r.room.name, amount: r.room.amount })
        })
    }
}

export default usePrice