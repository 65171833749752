import { JsonRpcProvider } from "@ethersproject/providers";
import { ethers } from "ethers";
import { getAddress } from "ethers/lib/utils";

let abi = [
    "event Transfer(address indexed from, address indexed to, uint256 indexed value)"
];
let itf = new ethers.utils.Interface(abi)
const RPC = process.env.REACT_APP_RPC_URL
let token = ethers.utils.getAddress(process.env.REACT_APP_NFT_ADDRESS + '');
const contract = new ethers.Contract(token as string, abi, new JsonRpcProvider({ url: RPC as string, timeout: 6000 }));
export const localStorageKey = (account: string) => `nfts-${account}`
const HeronNftConsumer = (account: string) => {
    let localStorageKey = `nfts-${account}`
    return {
        key: `transfer-${account}`,
        genesis: 0,
        safeDepth: 8,
        filter: [
            contract.filters.Transfer(null, account),
            contract.filters.Transfer(account, null)
        ],
        applyLogs: async (storage: any, logs: ethers.providers.Log[], unSafe?: boolean) => {
            if (!logs.length) {
                let nfts = JSON.parse(JSON.parse(localStorage.getItem(localStorageKey) || 'null') || 'null') || []
                await storage.setItem(localStorageKey, JSON.stringify(nfts))
                return
            }
            let events = logs.map(l => itf.parseLog(l))
            let nfts = JSON.parse(JSON.parse(localStorage.getItem(localStorageKey) || 'null') || 'null') || []
            events.forEach((log: any) => {
                let isTo = getAddress(log.args[1]) === getAddress(account)
                if (isTo) {
                    if (nfts.find((i: any) => Number(i) === log.args[2].toNumber()) == null) {
                        nfts.push(log.args[2].toNumber())
                    }
                } else {
                    nfts = nfts.filter((i: any) => Number(i) !== log.args[2].toNumber())
                }
            })
            nfts.sort(function (id1: number, id2: number) { return id1 - id2 })

            await storage.setItem(localStorageKey, JSON.stringify(nfts))

            return true
        }
    }
}
export default HeronNftConsumer