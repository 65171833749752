import { TRANSFER_NFT_FAIL, TRANSFER_NFT_START, TRANSFER_NFT_SUCCESS } from '../actions/types';
const initialState: {
  data: any
  list: any[]
  loading: boolean,
  error: any
  reFetch: boolean
} = {
  data: undefined,
  list: [],
  loading: false,
  error: null,
  reFetch: false
};
export default function (state = initialState, action: { type: any; payload: any; }) {
  const { type, payload } = action;
  switch (type) {
    case TRANSFER_NFT_START:
      return { ...state, loading: true }
    case TRANSFER_NFT_SUCCESS:
      return {...state, data: payload, loading: false, reFetch: !state.reFetch,  };
    case TRANSFER_NFT_FAIL:
      return {...state, loading: false, error: payload, reFetch: !state.reFetch };
    case 'TRANSFER_EVENT':
      return { ...state, list: [...state.list, payload] }
    case 'TRANSFER_EVENT_CLEAR':
      return { ...state, list: [] }
    default:
      return state;
  }
}