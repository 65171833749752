import { DatePicker, Space, Select, Button, notification, Row, Col, Input, Typography, Image, Tooltip, Popover, Divider, Grid } from 'antd'
import { SearchOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import { queryStringify, removeUrlParameter } from '../../helpers/url'
import { useWeb3React } from '@web3-react/core';

import UserIcon from '../../user.png'
import CalendarIcon from '../../calendar.png'
import ResortIcon from '../../resort.png'
import moment from 'moment';
import InputWithController from '../../components/InputComp/InputWithController';
import { useQuery } from '../../Hook/query';
import { fetchSuggestion } from '../../actions/reservation'
import { fetchResort } from '../../actions/resort'
import { insertUrlParam } from '../../helpers/url'
import { Resort } from '../../Types/Resort';
import RangePickerLimit from '../../components/RangePickerLimit';
import BoxSearchMobile from './BoxSearchMobile';
import { isNumber } from 'lodash';

const { Text } = Typography
const { useBreakpoint } = Grid;
const { Option } = Select;
const { RangePicker } = DatePicker;


const BoxSelect = ({ selectProps, data }: any) => {
    return <Select placement='topLeft' {...selectProps} bordered={false}>
        {data?.map((op: any) => <Option value={op._id} key={op._id} >{op.name}</Option>)}
    </Select>
}
export const Form = ({ onChangeAdult, onChangeChildren, defaultValue = {} }: any) => {
    const { t } = useTranslation();
    const onAdult = useCallback((adult) => {
        onChangeAdult(adult)
    }, [onChangeAdult])
    return <div >
        <InputWithController onChange={onAdult} defaultValue={defaultValue.adult} min={1} max={50} label={t('common.adult')} subLabel={t('home.adultNote')} />
        <Divider style={{
            margin: 0
        }} />
        <InputWithController defaultValue={defaultValue.children || 0} onChange={onChangeChildren} min={0} max={20} label={t('common.children')} subLabel={t('home.childrenNote')} />
    </div>
}
export const DropDownGuest = (props: any) => {
    const { adult, children } = props.query
    const { t } = useTranslation();

    const defaultValue = useMemo(() => {
        return { adult, children }
    }, [])

    return <Popover
        trigger='click'
        placement='top'
        content={<Form {...props} defaultValue={defaultValue} />}

    >
        <Space >
            <Image width={24} preview={false} src={UserIcon} />
            <Text className='heron-home-label left' strong>{t('common.travelers')}</Text>
        </Space>
        <Space>
            <Image width={24} className='hidden' preview={false} src={UserIcon} />
            <Text
                className='subLabel left'
                ellipsis={{ tooltip: `${adult} ${t('common.adult')} * ${children} ${t('common.children')}` }}
            >
                {`${adult} ${t('common.adult')} * ${children} ${t('common.children')}`}
            </Text>
        </Space>
    </Popover>
}

const BoxSearch = () => {
    const { account, error } = useWeb3React()
    const resorts: Resort[] = useSelector((state: any) => state?.resort?.data || []);
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation();
    const { params } = useQuery()
    const [query, setQuery] = useState<any>({
        adult: Number(params.adult) || 1,
        children: Number(params.children) || 0,
        resort: params.resort ? params.resort : null,
        checkIn: Number(params.checkIn) || null,
        checkOut: Number(params.checkOut) || null
    })
    const dic = useRef<any>([params.checkIn ? moment(Number(params.checkIn)) : null, params.checkOut ? moment(Number(params.checkOut)) : null])
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleSearch = useCallback(() => {
        query.checkIn = query.checkIn && isNumber(Number(query.checkIn)) && moment(Number(query.checkIn)).set({ "hour": process.env.REACT_APP_CHECKIN as any, "minute": 0, "second": 0, "millisecond": 0 }).valueOf() || null
        query.checkOut = query.checkOut && isNumber(Number(query.checkOut)) && moment(Number(query.checkOut)).set({ "hour": process.env.REACT_APP_CHECKOUT as any, "minute": 0, "second": 0, "millisecond": 0 }).valueOf() || null

        for (const key in query) {
            query[key] && insertUrlParam(key, query[key])
        }

        if (!(query.resort) || (params.resort && !resorts.find(r => r._id === query.resort))) {
            return notification.error({
                message: t('home.pickResort')
            })
        }
        if (!moment(query.checkIn).isValid() || !moment(query.checkOut).isValid()) {
            return notification.error({
                message: t('home.pickDates')
            })
        }

        setLoading(true)
        dispatch(fetchSuggestion(query,
            function (data: any) {
                if (!data?.length) {
                    setLoading(false)
                    return notification.error({
                        message: t('message.noSuggestion')
                    })
                }

                navigate(queryStringify('/rooms', query));
                dispatch(fetchResort())
                setLoading(false)
            },
            function (error: any) {
                setLoading(false)
                return notification.error({
                    message: error.toString()
                })
            }
        ))
    }, [query, navigate, resorts, params])

    const defaultResort = useMemo(() => {
        if (params.resort) {
            let resortSelected = resorts.find(r => r._id === params.resort)
            if (!resortSelected) {
                removeUrlParameter('resort')
            }
            return resortSelected?._id ? resortSelected?._id : null
        } else {
            return []
        }
    }, [resorts, params])
    const screens = useBreakpoint();
    if (Object.entries(screens).filter(screen => screen[1]).length < 3) {
        return <BoxSearchMobile
            loading={loading}
            onClick={handleSearch}
            disabled={!!(account && error)}
            query={query}
            defaultResort={defaultResort}
            resorts={resorts}
            setQuery={setQuery}
        />
    }
    return (
        <div className="box-search">
            <Row className='box-input'>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                    <Row wrap={false} style={{ display: 'flex', alignItems: 'center' }}>
                        <Col flex={'24px'}>
                            <Image width={24} preview={false} src={ResortIcon} />
                        </Col>
                        <Col flex={'auto'}>
                            <Text className='heron-home-label left' strong>{t('common.resort')}</Text>
                        </Col>
                    </Row>
                    <Row wrap={false}>
                        <Col flex={'24px'}>
                            <Image className='hidden' width={24} preview={false} src={ResortIcon} />
                        </Col>
                        <Col flex={'auto'}>
                            <BoxSelect
                                key={defaultResort}
                                data={resorts}
                                selectProps={{
                                    placeholder: <Text className='subLabel'>{t('home.pickResort')}</Text>,
                                    onChange: (value: any) => {
                                        setQuery({ ...query, resort: value })
                                    },
                                    showArrow: false,
                                    defaultValue: defaultResort,
                                    style: {
                                        width: '100%'
                                    }
                                }}
                            />
                        </Col>

                    </Row>

                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <div className='text-input' >
                        <Row>
                            <Col className='calendar-label' span={12}>
                                <Space>
                                    <Image preview={false} src={CalendarIcon} />
                                    <Text className='heron-home-label' strong>{t('common.checkIn')}</Text>
                                </Space>
                            </Col>
                            <Col className='calendar-label' span={12}>
                                <Space>
                                    <Image preview={false} src={CalendarIcon} />
                                    <Text className='heron-home-label' strong>{t('common.checkOut')}</Text>
                                </Space>
                            </Col>
                        </Row>
                        <RangePickerLimit
                            bordered={false}
                            maxRange={30}
                            minRange={1}
                            minDate={moment().endOf('day')}
                            placeholder={[t('home.addDate'), t('home.addDate')]}
                            onChange={(values) => {
                                setQuery({
                                    ...query,
                                    checkIn: values[0]?.valueOf(),
                                    checkOut: values[1]?.valueOf()
                                })
                            }}
                            defaultValue={(query.checkIn && query.checkOut && moment(Number(query.checkIn)).isValid() && moment(Number(query.checkOut)).isValid()) ? [moment(Number(query.checkIn)), moment(Number(query.checkOut))] : null}
                        />
                    </div>
                </Col>
                <Col className='guest' xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                    <DropDownGuest
                        query={query}
                        onChangeAdult={(adult: any) => setQuery({
                            ...query,
                            adult
                        })}
                        onChangeChildren={(children: any) => setQuery({
                            ...query,
                            children
                        })}
                    />
                </Col>
            </Row>
            <Button
                disabled={!!(account && error)}
                loading={loading}
                className='button-search'
                onClick={handleSearch}
                type="primary"
                size='large'
                shape='round'
            >{t('common.searchUppercase')}</Button>
        </div>
    )
}
export default BoxSearch