export type VOUCHER = {
    messageHash: string,
    signature: string,
    ids: number[]
}
export enum VOUCHER_STATUS {
    CREATED = 0,
    CLAIMED = 1,
    WITHDRAWN = 2
}
export type VOUCHER_PRIVATE = {
    _messageHash: string,
    _ids: number[],
    _isWithdraw?: boolean,
    _name: string,
    _status: VOUCHER_STATUS,
    _qr: string
}