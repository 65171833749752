import { Layout } from "antd"
import Header from './Header'
import {
    Outlet,
} from "react-router-dom";

import './index.less'
import Footer from "./Footer";
import WalletConnect from "./WalletConnect";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { chainPublisher } from '../workers/chain'

import { fetchResort } from '../actions/resort'
import ScrollToTop from "./ScrollToTop";
import { useWeb3React } from "@web3-react/core";
import { FETCH_NFT_START, FETCH_NFT_SUCCESS } from "../actions/types";
import Worker from "./Worker";
import { localStorageKey } from '../consumers/heron-nft'
import { useOpenWeb3Modal } from "../Hook/useOpenWeb3Modal";
import { CLAIM_EVENT_CLEAR } from '../actions/types'

const AppLayout = () => {

    const { openWeb3Modal, walletRef } = useOpenWeb3Modal()


    const { account } = useWeb3React();

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch({
            type: CLAIM_EVENT_CLEAR,
            payload: null
        })
        dispatch({
            type: 'TRANSFER_EVENT_CLEAR',
            payload: null
        })
    }, [account])


    return <Layout>
        <Header openWeb3Modal={openWeb3Modal} />
        <ScrollToTop />
        <Outlet />
        <Footer />
        {
            process.env.REACT_APP_NFT && <>
                <WalletConnect ref={walletRef} />
                <Worker />
            </>
        }

    </Layout>
}

export default AppLayout