import React, { CSSProperties, useEffect, useRef, useState, } from "react"
import QRCodeStyling, {
    DrawType,
    TypeNumber,
    Mode,
    ErrorCorrectionLevel,
    DotType,
    CornerSquareType,
    CornerDotType,
    FileExtension,
    Options
} from "qr-code-styling";
type QRProps = {
    value: string,
    size: number,
    logo: any,
    margin?: number,
    imageMargin?: number,
    divStyle?: CSSProperties,
    id?: any
}
const QR = React.memo((props: QRProps) => {
    const [options, setOptions] = useState<Options>({
        width: props.size,
        height: props.size,
        type: 'canvas' as DrawType,
        data: props.value,
        image: props.logo,
        margin: props.margin || 8,
        qrOptions: {
            typeNumber: 0 as TypeNumber,
            mode: 'Byte' as Mode,
            errorCorrectionLevel: 'L'
        },
        imageOptions: {
            hideBackgroundDots: true,
            imageSize: 0.7,
            margin: props.imageMargin || 16,
            crossOrigin: 'anonymous',
        },
        dotsOptions: {
            color: '#239F8B',
            type: 'rounded' as DotType
        },
        backgroundOptions: {
            color: '#fff'
        },
        cornersSquareOptions: {
            color: '#239F8B',
            type: 'extra-rounded' as CornerSquareType
        },
        cornersDotOptions: {
            color: '#239F8B',
            type: 'dot' as CornerDotType
        }
    });
    const [qrCode] = useState<QRCodeStyling>(new QRCodeStyling(options));

    const ref = useRef<HTMLDivElement>(null);
    const onDataChange = (value: string) => {
        setOptions(options => ({
            ...options,
            data: value
        }));
    };

    useEffect(() => {
        onDataChange(props.value)
    }, [props.value])

    useEffect(() => {
        if (ref.current) {
            qrCode.append(ref.current);
        }
    }, [qrCode, ref]);

    useEffect(() => {
        if (!qrCode) return;
        qrCode.update(options);
    }, [qrCode, options]);
    console.info('rerender', props.value)
    return <>
        <div id={props.id} style={props.divStyle} ref={ref} />
    </>
}, (pre, next) => pre.value === next.value && pre.id === next.id)
export default QR