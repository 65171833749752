import { Col, Collapse, Row, Space, Image, Typography, Tag } from "antd"
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import { formatMoney } from "../../helpers/number";
import CalendarIcon from '../../calendar.png'

const { Panel } = Collapse;
const { Text } = Typography
const Timeline = ({ priceDetail, nftDetails }: any) => {
    const { t } = useTranslation()
    if (process.env.REACT_APP_NFT) {
        if (!nftDetails) return null

        return <Space style={{ width: '100%' }} size={16} direction='vertical'>
            {
                _.map(nftDetails.group, (val, key) => {
                    let room = JSON.parse(key)
                    return <Row key={key}>
                        <Col span={12}>
                            <div
                                className="width-100"
                                style={{
                                    display: 'inline-flex'
                                }}>
                                <Text ellipsis={{
                                    tooltip: room.name
                                }} strong>
                                    {`${room.amount} x Room (${room.name}`}
                                </Text>
                                <Text strong>{`)`}</Text>
                            </div>
                        </Col>
                        <Col className="align-end" span={12}>
                            <Text strong>{`${val[0].pricePerNight} NFT x ${val[0]?.night} night`}</Text>
                        </Col>
                    </Row>
                })
            }
        </Space>
    }
    if (!priceDetail) return null
    return (
        <Collapse
            style={{
                maxHeight: 300,
                overflow: 'auto'
            }}
            expandIconPosition={'right'}
            ghost
            accordion
        >
            {
                _.map(priceDetail.group, (val, key) => {
                    let room = JSON.parse(key)
                    let _total = val.reduce((ret: any, p: any) => {
                        return ret + p.price * p.night
                    }, 0)
                    return <Panel header={<div style={{ width: '50%', display: 'inline-flex' }}><Text ellipsis={{ tooltip: room.name }} strong>{`${room.amount} x Room (${room.name}`}</Text> <Text>{`)`}</Text></div>} key={key} extra={<Text strong>{`${formatMoney(_total)} VND`}</Text>}>
                        <Space className="heron-order-gateway-timeline" direction='vertical'>
                            {
                                val.map((v: any, i: any) => {
                                    return <Row key={i} className="timeline">
                                        <Col span={2}>
                                            <Image preview={false} src={CalendarIcon} />
                                        </Col>
                                        <Col span={22}>
                                            <Space direction='vertical' style={{ width: '100%' }}>
                                                {v.label && <Tag color="red" className="event-name"><Text ellipsis={{ tooltip: v.label }}>{v.label}</Text></Tag>}
                                                <Text disabled>{t('common.date')}</Text>
                                                <Row >
                                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                                        <Text strong>{v.start}- {v.end}</Text>
                                                    </Col>
                                                    <Col style={{
                                                        textAlign: 'right'
                                                    }} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                                        <Text strong>{`${formatMoney(v.price)}VND x ${v.night} ${t('common.nightsLowercase')}`}</Text>
                                                    </Col>
                                                </Row>
                                            </Space>
                                        </Col>
                                    </Row>
                                })
                            }
                        </Space>
                    </Panel>
                })
            }
        </Collapse>
    )
}
export default Timeline