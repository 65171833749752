import { useCallback, useEffect, useState } from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import { Spin, Result, Button } from "antd";

import { useWeb3React, Web3ReactProvider } from "@web3-react/core";
import { ethers } from "ethers";
import { PersistGate } from "redux-persist/integration/react";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18next from "i18next";

import "web3-react-modal/dist/index.css";

import "./App.less";
import { store, persistor } from "./store";

import AppLayout from "./components/AppLayout";
import HomePage from "./Pages/Home";
import Payment from "./Pages/Payment";
import OrderGateway from "./Pages/OrderGateway";

import "./i18n";
import Vouchers from "./Pages/Vouchers";
import Swap from "./Pages/Swap";
import HeronNft2023 from "./Pages/HeronNft2023";
import { BlockNumberProvider } from "./Hook/useBlockNumber";

function getLibrary(provider: any) {
  const library = new ethers.providers.Web3Provider(provider);
  return library;
}

function NoMatch() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const goHome = useCallback(() => {
    navigate("/");
  }, []);
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button onClick={goHome} type="primary">
          {t("common.backHome")}
        </Button>
      }
    />
  );
}

function App() {
  process.env.MODE == "production" &&
    console.info(
      `----REACT_APP_API_ENDPOINT: ${process.env.REACT_APP_API_ENDPOINT}`
    );
  process.env.MODE == "production" &&
    console.info(`----REACT_APP_NFT: ${process.env.REACT_APP_NFT}`);
  process.env.MODE == "production" &&
    console.info(`----REACT_APP_UPDATED: ${process.env.REACT_APP_UPDATED}`);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 0);
  }, []);

  if (loading) {
    return (
      <div className="App">
        <Spin size="large" />
      </div>
    );
  }

  console.log("run");

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <I18nextProvider i18n={i18next}>
            <BlockNumberProvider>
              <Router>
                <Routes>
                  <Route path="/" element={<AppLayout />}>
                    <Route index element={<HomePage />} />
                    <Route path="/rooms/*" element={<OrderGateway />} />
                    <Route path="/vouchers/*" element={<Vouchers />} />
                    <Route path="/payment/ipn" element={<Payment />} />
                    <Route path="/swap" element={<Swap />} />
                    <Route path="/heron-nft-2024" element={<HeronNft2023 />} />
                    {/* <Route path="/payment/cancel" element={<PaymentCancel />} /> */}
                    <Route path="*" element={<NoMatch />} />
                  </Route>
                </Routes>
              </Router>
            </BlockNumberProvider>
          </I18nextProvider>
        </Web3ReactProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
