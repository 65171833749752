import Axios from "../helpers/axios"

interface SearchParams {
    resortId: string,
    roomId: string,
    checkIn: string,
    checkOut: string,
    nft?: boolean
}

const searchRoom = (params: SearchParams) => {
    if (process.env.REACT_APP_NFT) {
        params.nft = true
    }
    return Axios.post('api/roomType/search', params).then(res => res?.data?.data)
}
export interface SuggestParams {
    adult: string,
    amount: string,
    checkIn: string,
    children: string,
    resort: string[]
}
const fetchSuggestion = (params: any) => {
    if (process.env.REACT_APP_NFT) {
        params.nft = true
    }
    return Axios.post(`api/roomType/suggest`, params).then(res => res?.data?.data)
}
export default {
    searchRoom,
    fetchSuggestion
}