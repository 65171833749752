import { Button, Space, Typography } from "antd"
import { LeftOutlined } from '@ant-design/icons';
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const { Title } = Typography
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const goHome = useCallback(() => {
        navigate('/')
    }, [navigate])

    return <Space size={24} className='room-list-container' direction='vertical' >
        <Button onClick={goHome} icon={<LeftOutlined />} shape="round" >
            {t('common.goHome')}
        </Button>
        <Title level={4}>{"My vouchers"}</Title>
    </Space>
}