export const FETCH_RESORT_SUCCESS = "FETCH_RESORT_SUCCESS"

export const FETCH_ROOM_SUCCESS = "FETCH_ROOM_SUCCESS"
export const FETCH_ROOM_START = "FETCH_ROOM_START"

export const FETCH_ROOM_DETAIL_SUCCESS = "FETCH_ROOM_DETAIL_SUCCESS"

export const FETCH_RESERVATION_SUGGESTION = "FETCH_RESERVATION_SUGGESTION"
export const RESERVATION_CHANGE_ROOM = "RESERVATION_CHANGE_ROOM"
export const RESERVATION_UPDATE_ROOMS_INFO = "RESERVATION_UPDATE_ROOMS_INFO"

export const FETCH_NFT_SUCCESS = "FETCH_NFT_SUCCESS"
export const FETCH_NFT_START = "FETCH_NFT_START"
export const FETCH_NFT_FAIL = "FETCH_NFT_FAIL"

export const SWAP_NFT_SUCCESS = "SWAP_NFT_SUCCESS"
export const SWAP_NFT_FAIL = "SWAP_NFT_FAIL"
export const SWAP_NFT_START = "SWAP_NFT_START"

export const TRANSFER_NFT_SUCCESS = "TRANSFER_NFT_SUCCESS"
export const TRANSFER_NFT_FAIL = "TRANSFER_NFT_FAIL"
export const TRANSFER_NFT_START = "TRANSFER_NFT_START"

export const CREATE_PAYMENT = "CREATE_PAYMENT"

export const CLAIM_EVENT = "CLAIM_EVENT"
export const CLAIM_EVENT_CLEAR = "CLAIM_EVENT_CLEAR"