import { useWeb3React } from "@web3-react/core"
import { ethers } from "ethers"
import { useCallback, useState } from "react"
import bookingAbi from '../../../constants/booking.abi.json'
import { VOUCHER } from "../../../Types/Voucher"

const BOOKING_CONTRACT_ADDRESS = ethers.utils.getAddress(process.env.REACT_APP_BOOKING_ADDRESS + '')
const NFT_CONTRACT_ADDRESS = process.env.REACT_APP_NFT_ADDRESS + ''

const useBook = () => {
    const { account, library } = useWeb3React();
    const [loading, setLoading] = useState(false)

    const book = useCallback(async (nfts: any[], orderId: number) => {
        if (!account || !library) return
        try {
            setLoading(true)
            const signer = library.getSigner()
            const bookContract = new ethers.Contract(BOOKING_CONTRACT_ADDRESS, bookingAbi, signer)
            const tx = await bookContract.book(nfts, orderId);
            return tx
        } catch (error: any) {
            return Promise.reject(error.message || error)
        }
    }, [account, library])

    const bookVoucher = useCallback(async (orderId: number, voucher: VOUCHER) => {

        if (!account || !library) return Promise.reject('Please connect wallet.')

        if (!voucher.messageHash || !voucher.messageHash || !voucher.signature) return Promise.reject('Invalide voucher')

        try {
            setLoading(true)
            const signer = library.getSigner()

            const bookContract = new ethers.Contract(BOOKING_CONTRACT_ADDRESS, bookingAbi, signer)

            const tx = await bookContract.bookWithVoucher(orderId, voucher.signature, voucher.messageHash)

            return tx
        } catch (error: any) {
            setLoading(false)
            return Promise.reject(error.message || error)
        }
    }, [account, library])

    return { bookVoucher, book, bookLoading: loading, setLoadingBook: setLoading }
}

export default useBook