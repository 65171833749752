import { Typography } from "antd";
import './index.less'

import { useMemo } from "react";
import ResultPaymentNFT from "./ResultPaymentNFT";
import ResultPayment from "./ResultPayment";

const { Text } = Typography
const Payment = () => {
    const isNft = useMemo(() => {
        return process.env.REACT_APP_NFT
    }, [])
    if (isNft) return <ResultPaymentNFT />
    return <ResultPayment />
}

export default Payment;