import { JsonRpcProvider } from '@ethersproject/providers'
import AssistedJsonRpcProvider from 'assisted-json-rpc-provider'
import _ from 'lodash'
import axios from "axios"
import Chain from 'chain-publisher-v2';

const RPC = process.env.REACT_APP_RPC_URL

export const provider = new AssistedJsonRpcProvider(
    new JsonRpcProvider(RPC) as any,
    {
        rateLimitCount: 1,
        rateLimitDuration: 5000,
        rangeThreshold: 1024,
        maxResults: 1000,
        url: process.env.REACT_APP_SCAN_API,
        apiKeys: [],
        fetch: (url: string) => axios.get(url).then(res => res.data)
    } as any
);
export const chainPublisher = new Chain.ChainPublisher({
    provider: provider,
    size: 1024,
    storage: {
        setItem: async (key: string, value: any) => {
            localStorage.setItem(key, JSON.stringify(value))
        },
        getItem: async (key: string) => {
            return JSON.parse(localStorage.getItem(key) || 'null')
        },
        removeItem: async (key: string) => {
            localStorage.removeItem(key);
            return
        }
    }
})



