import React, { useMemo } from "react";
import { formatBalance, formatMoney } from "../../helpers/number";
import { useTokenBalance } from "./Hooks/useTokenBalance";
import { formatEther, getAddress } from "ethers/lib/utils";
import { useApproveToken } from "./Hooks/useApproveToken";
import { Button, InputNumber, Typography, Layout, notification, Space, Input } from "antd";
import { useWeb3React } from "@web3-react/core";
import { useSwap } from "./Hooks/useSwap";
import { BigNumber } from "ethers";
import { useSelector } from "react-redux";
import { useScreenWidth } from "../../Hook/useScreenWith";
import { useOpenWeb3Modal } from "../../Hook/useOpenWeb3Modal";
import "./index.less"
import ArrowUpIcon from "../../components/Icons/ArrowUpIcon";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { web3ModelGlobal } from '../../Hook/useOpenWeb3Modal'
import heronTokenLogo from "../../heron_token.png"
import { useListenSwapEvent } from "./Hooks/useListenSwapEvent";



const { Text } = Typography

const HERON_TOKEN_ADDRESS = getAddress(process.env.REACT_APP_HERON_TOKEN_ADDRESS + "");
const HERON_NFT_CONTRACT_ADDRESS = getAddress(process.env.REACT_APP_HERON_NFT_ADDRESS + "");

export default function HeronNft(props: any) {

  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: "#ffffff" }} spin />;

  const price = BigNumber.from("74000000000000000000000")

  const { active } = useWeb3React();
  const { balance, isBalanceLoading } = useTokenBalance(HERON_TOKEN_ADDRESS)
  const { approve, isApproved, approveLoading, isCheckingAllowance } = useApproveToken({
    tokenAddress: HERON_TOKEN_ADDRESS,
    spenderAddress: HERON_NFT_CONTRACT_ADDRESS,
  });

  const { swap } = useSwap({
    tokenAddress: HERON_TOKEN_ADDRESS,
    contractAddress: HERON_NFT_CONTRACT_ADDRESS
  })
  useListenSwapEvent(HERON_NFT_CONTRACT_ADDRESS)
  const isSwapping = useSelector((state: any) => {
    return state.swap.loading
  })

  const [amount, setAmount] = React.useState<number>(0)
  const [amountDisplay, setAmountDisplay] = React.useState<string | number | undefined>(undefined)
  function handleAmountChange(value: number) {
    setAmount(value)
    setAmountDisplay(value)
  }

  const screenWith = useScreenWidth()
  const isMobile = useMemo(() => {
    return screenWith < 768
  }, [screenWith])

  const swapAmout = useMemo(() => {
    if (balance.value.div(price).toNumber() > 10) {
      return 10
    } else {
      return balance.value.div(price).toNumber()
    }
  }, [balance.value, price])

  return <Layout.Content style={{
    gap: 40,
    paddingTop: 64,
    paddingBottom: 64,
    paddingLeft: 16,
    paddingRight: 16
  }}>
    <Text style={{
      fontSize: isMobile ? 24 : 48,
      fontWeight: 700,
      textAlign: 'center'
    }}>{isMobile ?
      <>
        <div>Swap from HERON Tokens</div>
        <div>to Heron NFTs</div>
      </>
      : "Swap from HERON Tokens to Heron NFTs"}</Text>
    <div className="swap-box">
      <div className="swap-header">
        <div className="header-label">
          <img src={heronTokenLogo} alt="Heron Token" style={{
            height: 24,
            borderRadius: "50%"
          }} />
          <Space >
            <Text style={{ fontWeight: 500 }}>HERON</Text>
          </Space>
        </div>
        <div className="header-content">
          {active &&
            <>
              <Space>

                <Text style={{
                  color: "#808191",
                  fontWeight: 500
                }}>Balance: {" "} {isBalanceLoading ? <Spin size="small" /> : formatMoney(formatBalance(balance))}
                </Text>

              </Space>
              {/* <Button style={{
                background: "#5FCBF0",
                color: "#FFFFFF",
                borderRadius: '12px',
              }}
                onClick={() => {
                  if (balance.value && price && balance.value.div(price).toNumber() < 10) {
                    handleAmountChange(balance.value.div(price).toNumber())
                  } else
                    handleAmountChange(10)
                }}
              >Max</Button> */}
            </>
          }
        </div>
      </div>
      <div className="input-content">

        <div className="input-box">
          <div>
            {swapAmout * Number(formatEther(price))}
          </div>
          <div>
            HERON
          </div>
        </div>

        <div className="swap-icon">
          <ArrowUpIcon />
        </div>
        <div className="pay-details">

          <div style={{
            display: "flex",
            alignItems: "center",
            gap: 12
          }}>
            <div className="dot" />
            <div className="pay-details-label">
              HERON NFTS
            </div>
          </div>

          <div className="pay-details-value">

            {active && <>
              Swapable: {" "}
              {isBalanceLoading ? <Spin size="small" /> :
                balance.value.div(price).toString()
              }
            </>}
          </div>
        </div>

        <div className="input-box">
          {/* <InputNumber type="number" className="input-number" placeholder="0" min={0} step={1} pattern="\d*" onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }} onChange={(value) => handleAmountChange(value as number)} value={amountDisplay} ></InputNumber> */}

          <div>
            {swapAmout}
          </div>

          <div>
            NFTs
          </div>
        </div>

      </div>
      <div className="swap-details" >
        <div className="swap-details-item">
          <div className="swap-details-item-label">
            Term
          </div>
          <div className="swap-details-item-value">
            10 NFTs per Transaction
          </div>
        </div>
        <div className="swap-details-item">
          <div className="swap-details-item-label">
            Price
          </div>
          <div className="swap-details-item-value">
            {Number(formatEther(price))} HERON per Heron NFT
          </div>
        </div>
      </div>
      {
        !active ? (
          <button onClick={() => {
            web3ModelGlobal?.current?.setVisible(true)
          }} className="swap-button">Connect Wallet</button>
        ) : !isApproved ? isCheckingAllowance ? <button className="swap-button" disabled> Checking Allowance ... </button> : (
          <button onClick={approve} className="swap-button" disabled={isApproved || approveLoading}>Approve
            {approveLoading && <Spin size="small" style={SpinStyle} indicator={antIcon} />}
          </button>
        ) : <button onClick={() => {
          if (swapAmout <= 0 || isNaN(swapAmout) || swapAmout > 10) {
            notification.error({
              message: "Invalid Swap Amount"
            })
            return
          }

          if (balance.value.div(price).toNumber() < swapAmout) {
            notification.error({
              message: "Insufficient Balance"
            })
            return
          }
          swap(swapAmout)
        }} className="swap-button" disabled={isSwapping || isBalanceLoading || swapAmout <= 0}>Swap{
            isSwapping && <Spin size="small" style={SpinStyle} indicator={antIcon} />
          }</button>
      }
    </div>


  </Layout.Content>

}

const SpinStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}


