import { Col, Space,Typography } from "antd"
import { useMemo } from "react"
import { formatMoney } from "../../helpers/number"
const {Text} = Typography
type BoxPriceProps = {
    room: any
}
const BoxPrice = (props: BoxPriceProps) => {
    const isNft = useMemo(() => {
        return process.env.REACT_APP_NFT
    }, [])
    if (isNft) return <BoxPriceNft {...props} />
    return <BoxPriceBasic {...props} />
}
const BoxPriceNft = (props: BoxPriceProps) => {
    return <Col className='card-room-price' span={12}>
        <Space className="box-price" size={2} direction='vertical'>
            <Text className="price-after-sale" type="success" strong >{`${Math.round(props?.room?.maxAdult / 2)} NFTs/night`}</Text>
        </Space>
    </Col>
}
const BoxPriceBasic = (props: BoxPriceProps) => {
    const priceSale = useMemo(() => {
        return Number(props?.room.priceToday - props?.room.priceToday * props?.room.saleToday / 100)
    }, [props.room])

    return <Col className='card-room-price' span={12}>
        <Space className="box-price" size={2} direction='vertical'>
            {props?.room.saleToday ? <Text className="price-pre-sale" disabled strong >VND {formatMoney(props?.room?.priceToday)}</Text> : null}
            <Text className="price-after-sale" type="success" strong >VND {formatMoney(priceSale)}</Text>
        </Space>
    </Col>
}
export default BoxPrice