import { FETCH_RESERVATION_SUGGESTION, RESERVATION_CHANGE_ROOM } from '../actions/types'
import RoomService from '../services/room'
import {SuggestParams} from '../services/room'
export const fetchSuggestion = (params: SuggestParams, onSuccess?: any, onFail?: any) => (dispatch: (arg0: any) => void) => {
    return RoomService.fetchSuggestion(params).then(suggest => {
        dispatch({
            type: FETCH_RESERVATION_SUGGESTION,
            payload: suggest,
        });
        onSuccess && onSuccess(suggest)
        return Promise.resolve();
    }, (error) => {
        onFail && onFail(error?.message || error)
    })
}
export const changeRoom = (newRoom: any) => (dispatch: (arg0: any) => void) => {
    return dispatch({
        type: RESERVATION_CHANGE_ROOM,
        payload: newRoom,
    });
}