import { Button, Col, Form, Input, Modal, notification, Row, Select, Typography } from 'antd'
import React, { useCallback, useImperativeHandle, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import useNewVoucher from '../Hooks/useNewVoucher';

const { Text } = Typography

const { Option } = Select;

const NewVoucher = React.forwardRef((props: any, ref) => {
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const walletLoading = useSelector((state: any) => state.wallet?.loading)
    const nfts = useSelector((state: any) => state?.wallet?.nfts || [])
    const [form] = Form.useForm();
    const { t } = useTranslation()
    const dic: any = useRef({
        isEdit: false,
        value: {

        },
    })

    const { createVouchers } = useNewVoucher()

    const handleCancel = useCallback(() => {
        setVisible(false)
        setLoading(false)
        dic.current = {
            isEdit: false,
            value: {

            },
        }
    }, [])

    const handleUseMax = useCallback(() => {
        if (!nfts.length) return
        setLoading(true)
        let nftPerVoucher = form.getFieldValue('value')
        form.setFieldsValue({
            amount: Math.floor(nfts.length / nftPerVoucher),
        })
        setLoading(false)
    }, [nfts, form])

    const handleOk = useCallback(() => {
        form.validateFields().then(async values => {
            let { amount, value, email } = values

            // Validate form

            if (isNaN(amount)) return notification.error({
                message: "Invalid amount"
            })
            if (amount > Math.floor(nfts.length / value)) return notification.error({
                message: "Amount must be less than or equal " + Math.floor(nfts.length / value)
            })
            setLoading(true)
            await createVouchers(amount, value)
            handleCancel()
        })
    }, [nfts, form, createVouchers, handleCancel])

    useImperativeHandle(
        ref,
        () => ({
            new: () => setVisible(true),
            edit: (initValue: any) => {

            }
        }),
        [],
    )
    return <>
        <Modal
            title={t('voucher.new.title')}
            visible={visible}
            onOk={handleOk}
            confirmLoading={loading || walletLoading}
            destroyOnClose
            onCancel={handleCancel}
        >
            <Form
                preserve={false}
                form={form}
                name="basic"
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 18 }}
                initialValues={{}}
                autoComplete="off"

            >
                <Form.Item
                    label={'Your number of NFTs: '}
                >
                    <Text style={{ textAlign: 'center' }} type='danger'>
                        {walletLoading ? 'Loading...' : `${nfts.length} NFTs`}
                    </Text>
                </Form.Item>

                <Form.Item
                    label={t('voucher.new.amount')}
                >
                    <Row>
                        <Col flex="auto">
                            <Form.Item
                                name="amount"
                                rules={[{ required: true, message: 'Please input amount!' }]}
                            >
                                <Input placeholder='Please input amount' />
                            </Form.Item>

                        </Col>
                        <Col flex="100px">
                            <Button onClick={handleUseMax} type='primary'>
                                Use max
                            </Button>
                        </Col>

                    </Row>

                </Form.Item>
                <Form.Item
                    name="value"
                    label={t('voucher.new.nftPerVoucher')}
                    hasFeedback
                    initialValue={1}
                    rules={[{ required: true, message: 'Please select a role!' }]}
                >
                    <Select disabled getPopupContainer={trigger => trigger.parentNode} placeholder="Please select number the nft per one voucher">
                        {
                            new Array(10).fill(0).map((_, index) => {
                                return <Option value={index + 1}>{`${index + 1} Nfts`}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
                {/* <Form.Item
                    label={t('common.email')}
                    name="email"
                    rules={[{ required: true, message: 'Please input email!' }]}
                >
                    <Input placeholder='Please input email!' />
                </Form.Item> */}
            </Form>
        </Modal>
    </>
})

export default NewVoucher;