import { Col, Row, Space, Typography } from "antd"
import { useMemo } from "react"
import { useTranslation } from 'react-i18next'
import ButtonGroup from "../../../components/ButtonGroup"
const { Text } = Typography
export default ({ rooms = [], totalAmountRoom = 0, selectedKey = null, setSelectedKey }: any) => {
    const { t } = useTranslation()
    const defaultKey = useMemo(() => {
        return rooms?.length ? rooms[0]?._id : null
    }, [rooms])
    const buttons = useMemo(() => {
        return rooms.map((r: any) => {
            return ({
                label: r.amount < 2 ? r.name : `${r.name}x${r.amount}`,
                key: r._id,
                props: {
                    shape: "round"
                },

            })
        })
    }, [rooms])
    return <Space className="heron-payment-gateway-container padding-16" direction='vertical'>
        <Row className="room-container">
            <Col className="overflow-scroll padding-16" span={16}>
                <ButtonGroup
                    onChange={(key: any) => {
                        setSelectedKey && setSelectedKey(key)
                    }}
                    defaultSelectedKey={defaultKey}
                    buttons={buttons}
                />
            </Col>
            <Col span={8}>
                <Space className="heron-payment-gateway-atribute padding-8" direction='vertical'>
                    <Text >
                        <Text className="heron-payment-gateway-atribute-total-text">{`${t('common.total')}`}</Text><Text className="heron-payment-gateway-atribute-amount-text">{` (${t('common.room')}): `}</Text><Text className="heron-payment-gateway-atribute-total-amount">{`${totalAmountRoom}`}</Text>
                    </Text>
                    {/* <span>{`${t('common.total')}(${t('common.room')}): ${totalAmountRoom}`} </span> */}
                </Space>
            </Col>
        </Row>
    </Space>
}