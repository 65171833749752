import { useWeb3React } from "@web3-react/core"
import { useEffect, useState } from "react"
import HeronNft2023Abi from '../../../constants/heronnft2023.abi.json'
import { useContract } from "../../../Hook/useContract"
import { useDispatch } from "react-redux"

export const useListenClaimEvent = (contractAddress: string) => {
  const { account, library } = useWeb3React()
  const [claimEvent, setClaimEvent] = useState<any>(null)
  const [claimEventList, setClaimEventList] = useState<any[]>([])
  const heronNft2023Contract = useContract(contractAddress, HeronNft2023Abi)
  const dispatch = useDispatch()

  useEffect(() => {
    if (account && library && heronNft2023Contract) {
      heronNft2023Contract.on('MintYearlyHERON', (tokenId, user, timestamp) => {
        const event = {
          tokenId,
          user,
          timestamp
        }
        if (account.toLowerCase() === user.toLowerCase()) {
          setClaimEvent(event)
          setClaimEventList((prev: any) => {
            return [...prev, event]
          })
          // dispatch({ type: 'CLAIM_EVENT', payload: event })

        }
      })
    }
    return () => {
      if (heronNft2023Contract) {
        heronNft2023Contract.removeAllListeners('MintYearlyHERON')
      }
    }
  }
    , [account, library, heronNft2023Contract])
  return { claimEvent, claimEventList }
}
