import { useWeb3React } from "@web3-react/core"
import { notification } from "antd"
import { ethers } from "ethers"
import { useCallback } from "react"
import { useSelector } from "react-redux"
import { groupNumber } from "../../../helpers/number"
import { useLoadingModal } from "../../../Hook/useLoading"
import { useVoucherContractSigner } from "../../../Hook/useVoucherContract"


const useNewVoucher = () => {
    const { library, account } = useWeb3React()
    const nfts = useSelector((state: any) => state?.wallet?.nfts || [])
    const { showLoading, hideLoading } = useLoadingModal()
    const voucherContract = useVoucherContractSigner()
    const createVouchers = useCallback(async (amount: number, numberNftPerVoucher: number = 1) => {
        if (!voucherContract) return Promise.reject("Invalid the contract");

        if (!library || !account) return Promise.reject("Please connect to wallet")

        let idss = groupNumber(nfts, amount, numberNftPerVoucher)
        let _messageHashs = idss.map(ids => ids.map(id => id + new Date().getTime())).map(ids => ethers.utils.keccak256(ethers.utils.solidityPack(['uint256[]'], [ids])))
        showLoading()
        try {
            let tx = await voucherContract.deposits(idss, _messageHashs)
            await tx.wait(1)
            hideLoading()
            notification.success({
                message: 'Voucher successfully created'
            })
        } catch (error: any) {
            hideLoading()
            notification.error({
                message: error?.message || error
            })
        }

    }, [library, nfts, voucherContract, account, hideLoading, showLoading])
    return { createVouchers }
}
export default useNewVoucher