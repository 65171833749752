import { useCallback, useMemo, useState, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import { Resort } from '../../../Types/Resort'
import { useQuery } from '../../../Hook/query'
const maxChar = 600
const ResortInfo = () => {
    const { params: { resort: resortId } } = useQuery()
    const [more, setMore] = useState(false)
    const resorts: Resort[] = useSelector((state: any) => state?.resort?.data || [])

    const resort = useMemo(() => {
        return resorts.find((r: any) => r._id === resortId)
    }, [resorts, resortId])
    const onMore = useCallback((e) => {
        const el = e.target.closest("A");
        if (el && e.currentTarget.contains(el)) {
            setMore(!more)
        }
    }, [more])
    useLayoutEffect(() => {
        if (resort?.description?.length && resort?.description?.length > maxChar) {
            setMore(true)
        } else {
            setMore(false)
        }
    }, [resort?.description?.length])
    return <div onClick={onMore} className='heron-reser-gateway-description-resort' dangerouslySetInnerHTML={{ __html: more ? resort?.description?.slice(0, maxChar) + "<a style='cursor: pointer' > ...more</a>" || '' : resort?.description || '' }}>

    </div>
};

export default ResortInfo;