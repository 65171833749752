
import { SWAP_NFT_FAIL, SWAP_NFT_START, SWAP_NFT_SUCCESS } from '../actions/types'
const initialState: {
  data: any
  loading: boolean,
  error: any
  reFetch: boolean
} = {
  data: undefined,
  loading: false,
  error: null,
  reFetch: false
};
export default function (state = initialState, action: { type: any; payload: any; }) {
  const { type, payload } = action;
  switch (type) {
    case SWAP_NFT_START:
      return { ...state, loading: true }
    case SWAP_NFT_SUCCESS:
      return { data: payload, loading: false, reFetch: !state.reFetch };
    case SWAP_NFT_FAIL:
      return { loading: false, error: payload, reFetch: !state.reFetch };
    default:
      return state;
  }
}