import { Col, Input, Row, Space, Typography, InputNumberProps } from "antd"
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'
import './style.less'
import { useCallback, useEffect, useState } from "react"
const { Title, Text } = Typography
export default ({
    label = '',
    subLabel = '',
    min = 0,
    max = 0,
    defaultValue = 0,
    onChange
}: {
    label?: string
    subLabel?: string,
    min?: number,
    max?: number,
    defaultValue?: number,
    onChange?: any
}) => {
    const [value, setValue] = useState(Number(defaultValue))

    min = Number(min)
    max = Number(max)

    const onPlus = useCallback(() => {
        if (value >= max) return
        let newValue = Number(value) + 1
        setValue(newValue)
        onChange && onChange(newValue)
    }, [value, max, onChange])
    const onMinus = useCallback(() => {
        if (value <= min) return
        let newValue = Number(value) - 1
        setValue(newValue)
        onChange && onChange(newValue)
    }, [value, min, onChange])

    if (!label && !subLabel) {
        return (
            <Space className="input-with-control">
                <Row>
                    <Col span={12}>
                        <div className="control left" >
                            <MinusCircleOutlined disabled onClick={onMinus} style={{
                                color: '#777E90',
                                fontSize: 20,
                                opacity: value <= min ? 0.7 : 1
                            }} />
                            <Text className="value" strong>{value}</Text>
                            <PlusCircleOutlined onClick={onPlus} style={{
                                color: '#777E90',
                                fontSize: 20,
                                opacity: value >= max ? 0.7 : 1
                            }} />
                        </div>
                    </Col>
                </Row>
            </Space>
        )
    }
    return <>
        <Space className="input-with-control">
            <Row>
                <Col span={12}>
                    <Space size={4} direction='vertical'>
                        <Text strong>
                            {label}
                        </Text>
                        <Text className="sub-label">
                            {subLabel}
                        </Text>
                    </Space>
                </Col>
                <Col span={12}>
                    <div className="control" >
                        <MinusCircleOutlined onClick={onMinus} style={{
                            color: '#777E90',
                            fontSize: 20,
                            opacity: value <= min ? 0.7 : 1
                        }} />
                        <Text className="value" strong>{value}</Text>
                        <PlusCircleOutlined onClick={onPlus} style={{
                            color: '#777E90',
                            fontSize: 20,
                            opacity: value >= max ? 0.7 : 1
                        }} />
                    </div>
                </Col>
            </Row>
        </Space>
    </>

}
