import { notification } from "antd"
import { BigNumber } from "ethers"
import { useCallback, useState } from "react"
import { isValidHttpUrl } from "../../../helpers/url"
import useVoucherContract from "../../../Hook/useVoucherContract"
import useFetchLongLink from "./useFetchLongLink"
const _getPrivateKey = (link: string) => {
    let params = (new URL(link)).searchParams;
    let privateKey = params.get("voucher") || ''
    return privateKey
}
const useRetrieveVoucher = () => {
    const [voucher, setVoucher] = useState<{
        signature: string,
        messageHash: string,
        ids: number[]
    }>({
        signature: '',
        messageHash: '',
        ids: []
    })
    const voucherContract = useVoucherContract()
    const { getLongLink } = useFetchLongLink()
    const handleCheckVoucher = useCallback(async (qrValue) => {
        try {
            if (!voucherContract || !qrValue) return setVoucher({
                messageHash: '',
                signature: '',
                ids: []
            })

            let privateKey = ''

            if (isValidHttpUrl(qrValue)) {
                let params = (new URL(qrValue))
                let shortLink = params.pathname
                let longUrl = await getLongLink(shortLink)
                privateKey = _getPrivateKey(longUrl)
            } else {
                privateKey = qrValue
            }

            let [_signature, _messageHash] = privateKey.split('-')

            let voucherDetail: BigNumber[] = await voucherContract.detail(_signature, _messageHash)

            let ids = voucherDetail.map(id => id.toNumber())

            setVoucher({
                signature: _signature,
                messageHash: _messageHash,
                ids
            })
        } catch (error: any) {
            notification.error({
                message: error?.reason || "Invalid voucher"
            })
        }
    }, [voucherContract])

    return { voucher, handleCheckVoucher }
}
export default useRetrieveVoucher