import { Button, Card, Col, List, Row, Space, Typography, Checkbox, notification } from "antd"
import { CloudDownloadOutlined } from '@ant-design/icons'
import { useCallback, useMemo, useRef, useState } from "react"
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import NewVoucher from "./Forms/NewVoucher"
import useRetrieveVoucher from "./Hooks/useRetrieveVoucher"
import { useCallBackShowQr } from "./Hooks/useShowQR"
import show from './show.jpg'
import { useApprovalVoucher } from "./Hooks/useApprovalVoucher"
import Header from "./Header"
import useWithdraw from "./Hooks/useWithdraw"

import './index.less'
import { useWeb3React } from "@web3-react/core"
import { web3ModelGlobal } from "../../Hook/useOpenWeb3Modal"
import { CheckboxValueType } from "antd/lib/checkbox/Group"
import { VOUCHER_PRIVATE, VOUCHER_STATUS } from "../../Types/Voucher"
import QRComp from '../../components/QR'
// import voucher from '../../voucher.jpg'
import voucher from '../../v-front.jpg'
import voucherBack from '../../v-back.jpg'
import logoQR from '../../logo_qr.png'
import { useLoadingModal } from "../../Hook/useLoading";
const { Title, Text } = Typography

const qrSize = 128 * 2.5

const vW = 2560;
const vH = 1201

const QR = (props: { messageHash: string, isClaim?: boolean, isWithdraw?: boolean, name: string, qr?: string, handleShowQr: any }) => {
    const { showLoading, hideLoading } = useLoadingModal()
    const downloadQR = useCallback(() => {
        const canvas: any = document.getElementById(props.messageHash)?.firstChild;
        if (!canvas) return
        const pngFile = canvas.toDataURL("image/png", 1);
        const downloadLink = document.createElement("a");
        downloadLink.download = `${props.name}.png`;
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
    }, [props]);

    const exportPDF = useCallback(() => {
        showLoading()
        setTimeout(() => {
            const canvas: any = document.getElementById(props.messageHash)?.firstChild;
            const name = props.name
            const qr = canvas.toDataURL('img/png', 1);
            const pdf: jsPDF = new jsPDF('l', 'px', [vW, vH]);
            const pageWidth = pdf.internal.pageSize.getWidth();
            pdf.addImage(
                voucher,
                'PNG',
                pageWidth / 2 - vW / 2,
                0,
                vW,
                vH
            );
            pdf.addImage(
                qr,
                'PNG',
                1323,
                492,
                qrSize,
                qrSize
            );
            pdf.addPage('l');

            pdf.setPage(2);

            pdf.addImage(
                voucherBack,
                'PNG',
                pageWidth / 2 - vW / 2,
                0,
                vW,
                vH
            );
            pdf.save(`${name}.pdf`);
            hideLoading()
        }, 100)
    }, [props])


    const handleShowQr = useCallback(() => {
        props.handleShowQr && props.handleShowQr(props.messageHash)
    }, [props])

    return <>
        {
            props.qr ?
                <div
                    className="qr-encode"
                    style={{
                        alignItems: 'center',
                        height: 150,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        overflow: 'hidden',
                        position: 'relative',

                    }}>
                    <QRComp
                        id={props.messageHash}
                        size={qrSize}
                        logo={logoQR}
                        imageMargin={4}
                        divStyle={{
                            transform: `scale(0.4)`
                        }}
                        value={props.qr}
                    />

                    {
                        props.isClaim && <div style={{
                            position: 'absolute',
                            top: 0, left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Title level={3} type='danger'>CLAIMED</Title>
                        </div>
                    }
                    {
                        props.isWithdraw && <div style={{
                            position: 'absolute',
                            top: 0, left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Title level={3} type='danger'>WITHDRAWN</Title>
                        </div>
                    }
                    <Space direction='horizontal' className="qr-action">
                        <Button onClick={downloadQR} icon={<CloudDownloadOutlined />}>PNG</Button>
                        <Button onClick={exportPDF} icon={<CloudDownloadOutlined />}>PDF</Button>
                    </Space>
                </div> :
                <div style={{
                    height: 150,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    position: 'relative'
                }}>
                    <QRComp
                        divStyle={{
                            transform: `scale(0.4)`
                        }}
                        size={qrSize}
                        logo={logoQR}
                        value={'https://bitly.is/3U0cs2V'}
                        imageMargin={4}
                    />

                    <div style={{
                        position: 'absolute',
                        top: 4, left: 4,
                        right: 4,
                        bottom: 4,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        background: 'rgba(255,255,255,0.9)'
                    }}>
                        <img
                            onClick={handleShowQr}
                            style={{
                                width: 48,
                                height: 48,
                                cursor: 'pointer'
                            }}
                            src={show}
                        />
                        {
                            props.isClaim && <Title level={3} type='danger'>CLAIMED</Title>
                        }
                        {
                            props.isWithdraw && <Title level={3} type='danger'>WITHDRAWN</Title>
                        }
                    </div>
                </div>
        }
    </>
}

const ButtonNewVoucher = (props: { onNewVoucher: any, onFilter: (filter: any) => void }) => {
    const { isApproval, handleApproval } = useApprovalVoucher()
    const { account } = useWeb3React()
    const handleFilter = useCallback((checkedValues: CheckboxValueType[]) => {
        return props.onFilter(checkedValues)
    }, [props])
    if (!account) return <Button
        type='primary'
        onClick={() => {
            web3ModelGlobal?.current?.setVisible(true)
        }}
    >
        Connect Wallet
    </Button>

    return <Space>
        {
            isApproval ?
                <Button
                    type='primary'
                    onClick={props.onNewVoucher}
                >
                    New voucher
                </Button> :
                <Button
                    type='primary'
                    onClick={handleApproval}
                >
                    Approval
                </Button>
        }
        <Checkbox.Group options={[{ label: 'Claimed', value: VOUCHER_STATUS.CLAIMED }, { label: 'Withdrawn', value: VOUCHER_STATUS.WITHDRAWN }, { label: 'Created', value: VOUCHER_STATUS.CREATED }]} defaultValue={[]} onChange={handleFilter} />
    </Space>
}
const Vouchers = () => {
    const newForm = useRef<any>()
    const { showQR } = useCallBackShowQr()
    const handleNewVoucher = useCallback(() => {
        newForm.current?.new()
    }, [])
    const [filter, setFilter] = useState<any[]>([])
    const { vouchers, loading, setVouchers } = useRetrieveVoucher()
    const { withdraw } = useWithdraw()

    const dataSource = useMemo(() => {
        let newDataSource = vouchers.filter((v: VOUCHER_PRIVATE) => {
            if (!filter.length) return true

            return filter.includes(v._status)
        })
        return newDataSource
    }, [vouchers, filter])

    const handleShowQr = useCallback(async (messageHash: string) => {
        let qr = await showQR(messageHash)

        if (qr == null || qr === undefined) return

        let newDataSource = vouchers.map(v => {
            if (v._messageHash !== messageHash) return v
            v._qr = qr as string
            return v
        })

        setVouchers(newDataSource)
    }, [vouchers, showQR, setVouchers])

    return (
        <Space direction='vertical' className="heron-vouchers-container">
            <Header />
            <NewVoucher ref={newForm} />
            <List
                header={<ButtonNewVoucher onFilter={setFilter} onNewVoucher={handleNewVoucher} />}
                loading={loading}
                grid={{
                    gutter: 16,
                    column: 4,
                    xs: 1,
                    sm: 2,
                    md: 2,
                    lg: 4,
                    xl: 4,
                    xxl: 4,
                }}
                rowKey={(item) => item._messageHash}
                dataSource={dataSource}
                renderItem={item => (
                    <List.Item>
                        <Card
                            hoverable
                            // style={{ width: 240 }}
                            cover={<QR qr={item._qr} handleShowQr={handleShowQr} messageHash={item._messageHash} isClaim={item._status === VOUCHER_STATUS.CLAIMED} name={item._name} isWithdraw={item._status === VOUCHER_STATUS.WITHDRAWN} />}
                        >
                            <Card.Meta title={item._name} description={
                                <Row>
                                    <Col span={12}>
                                        <Text>{`NFTs: ${item._ids.toString()}`}</Text>
                                    </Col >
                                    {
                                        item._status === VOUCHER_STATUS.CREATED && <Col style={{
                                            textAlign: 'right'
                                        }} span={12}>
                                            <Button onClick={() => withdraw(item._messageHash)} type='primary'>Withdraw</Button>
                                        </Col>
                                    }
                                </Row>
                            } />
                        </Card>
                    </List.Item>
                )}
            />
        </Space>
    )
}
export default Vouchers
