import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { MaxUint256 } from '@ethersproject/constants'

import Bep20Abi from '../../../constants/heronbep20.abi.json'
import { BigNumber, ethers } from "ethers";
import { notification } from "antd";
import { useContractSigner } from "../../../Hook/useContract";

export const useApproveToken = (
  {
    tokenAddress,
    spenderAddress,
    amount = MaxUint256
  }: {
    tokenAddress: string
    spenderAddress: string
    amount?: BigNumber
  }
) => {
  const { account, library } = useWeb3React()
  const [isApproved, setIsApproved] = useState(false)
  const [isCheckingAllowance, setIsCheckingAllowance] = useState(false)
  const [loading, setLoading] = useState(false)
  const bep20ContractSigner = useContractSigner(tokenAddress, Bep20Abi)
  const approve = useCallback(async () => {
    if (!account || !library || !bep20ContractSigner) {
      notification.error({
        message: "Please connect to wallet"
      })
      return Promise.reject('Missing account or nftContract')
    }
    try {
      setLoading(true)
      const tx = await bep20ContractSigner.approve(spenderAddress, amount)
      await tx.wait(1)
      setIsApproved(true)
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      setIsApproved(false)
      notification.error({
        message: error?.message || error
      })
    }
  }, [account, library, bep20ContractSigner])

  useEffect(() => {
    if (!account || !library || !bep20ContractSigner) return
    const exec = async () => {
      try {
        setIsCheckingAllowance(true)
        let ret = await bep20ContractSigner.allowance(account, spenderAddress)
        setIsApproved(ret.gt(0))
        setIsCheckingAllowance(false)
      } catch (error: any) {
        notification.error({
          message: error?.message || error
        })
      }
    }
    exec()
  }, [account, library, bep20ContractSigner])

  return { isApproved, approve, approveLoading: loading, isCheckingAllowance }
}