import { Layout, Button } from "antd"
import { SoundOutlined } from '@ant-design/icons';

import BoxSearch from './BoxSearch'
import './index.less'
import { useCallback, useRef, useState } from "react";

const HomePage = () => {
    const video = useRef<any>()
    const [muted, setMuted] = useState(true)
    const handleChangeMuted = useCallback(() => {
        video.current.muted = !muted
        video.current.play()
        setMuted(!muted)
    }, [muted])
    return <Layout.Content className="home-wrapper">
        <video
            ref={video}
            onClick={() => {
                window.open('https://youtu.be/q6PAvBBmMfM')
            }}
            id="background-video"
            autoPlay
            loop
            muted
        >
            <source src={`${process.env.REACT_APP_API_BASE_URL}/intro.mp4`} type="video/mp4" />

        </video>
        <Button
            onClick={handleChangeMuted}
            type={muted ? "text" : "primary"}
            className="button-muted"
            icon={<SoundOutlined size={20} />}
        />
        <BoxSearch />
    </Layout.Content>
}

export default HomePage