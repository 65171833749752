import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useMemo, useState } from "react";

import nftAbi from '../../../constants/nft.abi.json'
import { ethers } from "ethers";
import { notification } from "antd";

const BOOKING_CONTRACT_ADDRESS = ethers.utils.getAddress(process.env.REACT_APP_BOOKING_ADDRESS + '')
const NFT_CONTRACT_ADDRESS = ethers.utils.getAddress(process.env.REACT_APP_NFT_ADDRESS + '')

const useApprovedForAll = () => {
    const { account, library } = useWeb3React()
    const [isApprovedForAll, setIsApprovedForAll] = useState(false)
    const [loading, setLoading] = useState(false)
    const approve = useCallback(async () => {
        if (!account || !library) return
        const signer = library.getSigner()
        const nftContract = new ethers.Contract(NFT_CONTRACT_ADDRESS, nftAbi, signer)
        try {
            setLoading(true)
            const tx = await nftContract.setApprovalForAll(BOOKING_CONTRACT_ADDRESS, true)
            await tx.wait(1)
            setIsApprovedForAll(true)
            setLoading(false)
        } catch (error: any) {
            setLoading(false)
            setIsApprovedForAll(false)
            notification.error({
                message: error?.message || error
            })
        }
    }, [account, library])
    useEffect(() => {
        if (!account || !library) return
        const signer = library.getSigner()
        const nftContract = new ethers.Contract(NFT_CONTRACT_ADDRESS, nftAbi, signer)
        const exec = async () => {
            try {
                let ret = await nftContract.isApprovedForAll(account, BOOKING_CONTRACT_ADDRESS)
                setIsApprovedForAll(ret)
            } catch (error: any) {
                notification.error({
                    message: error?.message || error
                })
            }
        }
        exec()
    }, [account, library])

    return { isApprovedForAll, approve, approveLoading: loading }
}

export default useApprovedForAll;