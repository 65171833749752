import hmacSHA512 from 'crypto-js/hmac-sha512';
import Hex from 'crypto-js/enc-hex';
import { ethers } from 'ethers';
/**
 * Sign object return hex string
 * @param value 
 * @returns 
 */
export const sign = (value = {}) => {
    const privateKey = process.env.REACT_APP_PRIVATE_KEY;

    const signed = hmacSHA512(JSON.stringify(value), privateKey as string)
    return Hex.stringify(signed)
}

export const getProvider = () => {
    return new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_URL)
}