import { Button, Result, Space, Spin, Typography } from "antd";
import './index.less'
import { useNavigate } from 'react-router-dom'

import useTransaction from "./Hooks/useTransaction";
import { useQuery } from "../../Hook/query";
const { Text, Link } = Typography
const ResultPaymentNFT = () => {
    const { params } = useQuery()
    const { loading, error } = useTransaction()

    const navigate = useNavigate()

    const goHome = () => {
        navigate('/')
    }
    if (loading) {
        return <Result
            icon={<Spin />}
            title="Retrieve transaction results..."
        />
    }
    if (error) {
        console.info('Transaction failed',error)
        return <Result
            status="error"
            title={"Transaction failed."}
            subTitle={<Text>
                <Link href={process.env.REACT_APP_SCAN_URL + "/tx/" + params.transactionHash} target="_blank">
                    View transaction on bscscan
                </Link>
            </Text>}
            extra={<Button onClick={goHome} type="primary">Back Home</Button>}
        />
    }
    return <Result
        status="success"
        title="Reservation Confirmed!"
        subTitle={<Text>
            <Link href={process.env.REACT_APP_SCAN_URL + "/tx/" + params.transactionHash} target="_blank">
                View transaction on bscscan
            </Link>
        </Text>}
        extra={<Button onClick={goHome} type="primary">Back Home</Button>}
    />
}

export default ResultPaymentNFT;