import { moment } from '../helpers/time'

export const CONNECTED = 'HERON_CONNECTED'

export const TIMELINE_EVENT: any = {
    WEEKEND: {
        match: (time: any) => {
            return moment(time).day() == 0 || moment(time).day() == 6;
        },
        label: "Loop"
    }
};

export const TIMELINE_LABEL = {
    "BASIC": "",
    "EVENT": "EVENT",
    "SPECIAL": "SPECIAL"
}

export const REMINDER_MATCH_FUNC: any = {
    EVERY_WEEK: {
        match: (time: any, compareTime: any) => {
            return moment(time).day() === moment(compareTime).day();
        }
    },
    EVERY_MONTH: {
        match: (time: any, compareTime: any) => {
            return moment(time).date() === moment(compareTime).date();
        }
    },
    EVERY_YEAR: {
        match: (time: any, compareTime: any) => {
            return moment(time).date() === moment(compareTime).date() && moment(time).month() === moment(compareTime).month();
        }
    }
};