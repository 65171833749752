import { useEffect, useMemo } from "react"
import RoomList from "../../../Pages/Rooms"
import OrderInfo from "./OrderInfo"
import OrderInfoNFT from "./OrderInfoNFT"
import ResortInfo from "./ResortInfo"

import { useQuery } from "../../../Hook/query"
import { useDispatch } from "react-redux"
import { fetchSuggestion } from "../../../actions/reservation"

import './index.less'
const ReservationGateway = () => {
    const { params } = useQuery()
    const dispatch = useDispatch()
    const isNft = useMemo(() => {
        return process.env.REACT_APP_NFT
    }, [])
    useEffect(() => {
        dispatch(fetchSuggestion(params))
    }, [])
    return <>
        <div className="heron-reser-gateway-container-info">
            <div className="heron-reser-gateway-container-right">
                {isNft ? <OrderInfoNFT /> : <OrderInfo />}
            </div>
            <div className="heron-reser-gateway-container-left">
                <ResortInfo />
            </div>
        </div>
        <RoomList />
    </>
}
export default ReservationGateway;