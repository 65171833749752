
import { FETCH_NFT_FAIL, FETCH_NFT_SUCCESS, FETCH_NFT_START } from '../actions/types'
const initialState: {
    nfts: [],
    loading: boolean,
    error: any
} = {
    nfts: [],
    loading: false,
    error: null
};
export default function (state = initialState, action: { type: any; payload: any; }) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_NFT_START:
            return { ...state, loading: true }
        case FETCH_NFT_SUCCESS:
            return { nfts: payload, loading: false };
        case FETCH_NFT_FAIL:
            return { loading: false, error: payload };
        default:
            return state;
    }
}