import { Col, Divider, Image, Row, Space, Typography, List } from "antd"
import flag from '../../flag.png'
import { useTranslation } from 'react-i18next'
import { useMemo } from "react"

const { Text } = Typography
const Utils = (props: { utils: any[] } = { utils: [] }) => {
    const { t } = useTranslation()
    const data = useMemo(() => {
        return props?.utils?.filter(u => u.val)
    }, [props?.utils])
    if (!data?.length) {
        return null
    }
    return <List
        grid={{
            gutter: 16,
            column: 2,
        }}
        dataSource={data}
        renderItem={(item, i) => (
            <List.Item>
                <Row style={{
                    alignItems: 'center'
                }}>
                    <Col flex={'auto'}>
                        <Text
                            className={`atr ${i % 2 != 0 ? 'text-right' : 'text-left'}`}
                            ellipsis={{ tooltip: `${t(`payment.utilities.${item.key}`) ?? 'Unknown util'}: ${item.val} ${item.unit ? t(`payment.utilities.${item.unit}`) : ''} ` }}
                        >
                            {` ${t(`payment.utilities.${item.key}`) ?? 'Unknown util'}: ${item.val} ${item.unit ? t(`payment.utilities.${item.unit}`) : ''}`}
                        </Text>
                    </Col>

                </Row>
            </List.Item>
        )}
    />
}
export default Utils;