import { FETCH_RESORT_SUCCESS } from '../actions/types'
import ResortService from '../services/resort'
import { Resort } from '../Types/Resort'
export const fetchResort = () => (dispatch: (arg0: any) => void) => {
    return ResortService.fetchResort().then((resorts: Resort[]) => {
        dispatch({
            type: FETCH_RESORT_SUCCESS,
            payload: resorts,
        });

        return Promise.resolve();
    }, (error) => {
        console.error(error?.message)
    })
}