import PaymentService from '../services/payment'
import { ReservationParams } from '../services/payment'
import { IpnParams } from '../services/payment'
/**
 * Create reservation with status PENDING_PAYMENT
 * @param params 
 * @param onSuccess 
 * @param onFail 
 * @returns 
 */
export const create = (params: ReservationParams, onSuccess?: any, onFail?: any) => (dispatch: (arg0: any) => void) => {
    return PaymentService.create(params).then((data) => {
        onSuccess && onSuccess(data)
        return Promise.resolve(data);
    }, (error) => {
        onFail && onFail(error?.message || error)
    })
}
/**
 * UPDATE STATUS PAYMENT WHEN VNPAY RETURN
 * @param params 
 * @param onSuccess 
 * @param onFail 
 * @returns 
 */
export const ipn = (params: IpnParams, onSuccess?: any, onFail?: any) => (dispatch: (arg0: any) => void) => {
    return PaymentService.ipn(params).then(() => {
        onSuccess && onSuccess()
        return Promise.resolve();
    }, (error) => {
        onFail && onFail(error?.message)
    })
}
/**
 * CANCEL PAYMENT WHEN CUSTOMER CLICK BUTTON CANCEL IN EMAIL
 * @param params 
 * @param onSuccess 
 * @param onFail 
 * @returns 
 */
export const cancelPayment = (params: any, onSuccess?: any, onFail?: any) => (dispatch: (arg0: any) => void) => {
    return PaymentService.cancelPayment(params).then(() => {
        onSuccess && onSuccess()
        return Promise.resolve();
    }, (error) => {
        onFail && onFail(error?.message)
    })
}