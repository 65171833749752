import { Col, Row, Typography, Button, Alert, Space, notification } from "antd"
import { IdcardOutlined, CameraOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useRef, useState } from "react";

import QrScanner from 'qr-scanner';

import useRetrieveVoucher from "../Hooks/useRetrieveVoucher";
import { useTranslation } from "react-i18next";

const { Text } = Typography

type VoucherProps = {
    onVoucher: (ethSignedMessageHash: string, _signature: string, ids: number[]) => void,
    onRemove: () => void
}
type QRScannerProps = {
    onResult: (value: string) => void
}
const QRScanner = (props: QRScannerProps) => {

    useEffect(() => {
        const videoElem: any = document.getElementById("video");
        if (videoElem) {
            const qrScanner = new QrScanner(
                videoElem,
                result => {
                    qrScanner.stop()
                    props.onResult(result.data)
                },

                {
                    onDecodeError: error => {
                        console.log(error)
                    },
                    highlightScanRegion: true,
                    highlightCodeOutline: true
                },
            );
            qrScanner.start()
        }

    }, [])
    return <video className="heron-payment-gateway-qr-container-qr-video" style={{
        objectFit: 'cover'
    }} id="video"></video>
}
const Voucher = (props: VoucherProps) => {
    const [visible, setVisible] = useState(false)
    const { t } = useTranslation()
    const { onVoucher, onRemove } = props
    const { voucher: { messageHash, signature, ids }, handleCheckVoucher } = useRetrieveVoucher()

    const handleSetVisible = useCallback(() => {
        setVisible(!visible)
    }, [visible])

    const removeVoucher = useCallback(() => {
        handleCheckVoucher('')
        onRemove()
    }, [onRemove, handleCheckVoucher])

    useEffect(() => {
        messageHash && signature && ids.length && onVoucher && onVoucher(messageHash, signature, ids)
    }, [messageHash, signature, ids, onVoucher])

    return <Row style={{
        marginBottom: 16
    }}>
        <Col style={{
            display: 'flex',
            alignItems: 'center',
        }} span={12}>
            <Space>
                <IdcardOutlined />
                <Text>
                    {t('voucher.title')}
                </Text>
            </Space>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
            <Space>
                <Button type='text' onClick={handleSetVisible}>
                    <Text>{'Scan'}</Text>
                    <CameraOutlined />
                </Button>
                <ButtonUploadQr handleCheckVoucher={handleCheckVoucher} />
            </Space>
            {
                visible && <div
                    className="heron-payment-gateway-qr-container"
                    onClick={handleSetVisible}
                    style={{
                        position: 'fixed',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 2000
                    }}>
                    <QRScanner onResult={val => {
                        handleCheckVoucher(val)
                        handleSetVisible()
                    }} />
                </div>
            }
        </Col>
        <Col span={24}>
            {
                signature && <Alert onClose={removeVoucher} closable message={`You have ${ids.length} NFTs from this voucher.`} type="success" />
            }
        </Col>
    </Row>

}

const ButtonUploadQr = (props: { handleCheckVoucher: Function }) => {
    const inputFile = useRef<any>(null)
    const [loading, setLoading] = useState(false)
    const handleUpload = useCallback(() => {
        inputFile.current.click();
    }, [])
    const onFileChange = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();

        var file = event.target.files[0];

        var reader = new FileReader();

        setLoading(true)

        reader.onload = function (e: any) {
            QrScanner.scanImage(e.target.result)
                .then(result => {
                    console.log(result)
                    setLoading(false)
                    props.handleCheckVoucher(result)
                    inputFile.current.value = ''
                })
                .catch(error => {
                    setLoading(false)
                    inputFile.current.value = ''
                    notification.error({
                        message: "Invalid voucher"
                    })
                });
        };

        reader.readAsDataURL(file);

    }, [props])
    return <>
        <input onChange={onFileChange} type='file' id='file' ref={inputFile} style={{ display: 'none' }} />
        <Button loading={loading} type='text' onClick={handleUpload}>
            <Text>{'Upload'}</Text>
            <CloudUploadOutlined />
        </Button>
    </>
}

export default Voucher