
import { Room, RoomBooked } from '../Types/Room';
import { FETCH_RESERVATION_SUGGESTION, RESERVATION_CHANGE_ROOM, RESERVATION_UPDATE_ROOMS_INFO } from '../actions/types'
const initialState: {
    rooms: RoomBooked[],
    loading: boolean,
    error: any
} = {
    rooms: [],
    loading: false,
    error: null
};
export default function (state = initialState, action: { type: any; payload: any; }) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_RESERVATION_SUGGESTION:
            return { ...state, rooms: payload };
        case RESERVATION_UPDATE_ROOMS_INFO:
            // Update timelines of RoomBooked
            let newRoomBooked: RoomBooked[] = state.rooms?.map((r: RoomBooked) => {
                let tmpRoom = payload.find((room: Room) => room._id.toString() === r._id.toString())
                return {
                    amount: r.amount,
                    ...tmpRoom
                }
            })
            return { ...state, rooms: newRoomBooked };
        case RESERVATION_CHANGE_ROOM:
            return { ...state, rooms: payload };
        default:
            return state;
    }
}