import { Button, Result, Spin } from "antd";
import './index.less'
import { useNavigate } from 'react-router-dom'

import useIpn from "./Hooks/useIpn";

const ResultPayment = () => {
    const { loading, error } = useIpn()

    const navigate = useNavigate()

    const goHome = () => {
        navigate('/')
    }
    if (loading) {
        return <Result
            icon={<Spin />}
            title="Retrieve payment results..."
        />
    }
    if (error) {
        return <Result
            status="error"
            title={error}
            extra={<Button onClick={goHome} type="primary">Back Home</Button>}
        />
    }
    return <Result
        status="success"
        title="Reservation Confirmed!"
        extra={<Button onClick={goHome} type="primary">Back Home</Button>}
    />
}

export default ResultPayment;