import { Col, Image, Row, Space, Typography } from "antd"
import { getImageUrl } from "../../helpers/url"
import big from '../../big.png'
import { useQuery } from "../../Hook/query"
import { useSelector } from "react-redux"
import { useMemo } from "react"
import ImageCollection from "../../components/ImageCollection"
import { Resort } from "../../Types/Resort"
const { Title } = Typography
export default (props: any) => {
    const { params: { resort: resortId } } = useQuery()

    const resorts: Resort[] = useSelector((state: any) => state?.resort?.data || [])

    const resort = useMemo(() => {
        return resorts.find((r: Resort) => r._id == resortId)
    }, [resorts])

    return <Row>
        <Col span={24}>
            <Title level={4}>{resort?.name}</Title>
        </Col>
        <Col className="heron-resort-image-big" span={18}>
            <ImageCollection className="heron-card-avatar" imgs={resort?.imgs?.length ?
                resort.imgs.map((img: string) => getImageUrl(img)) : [big]
            } />
        </Col>

        <Col className="heron-resort-image-small" span={6}>
            <div>
                {
                    resort?.imgs && resort?.imgs.length > 1 && resort?.imgs.slice(1, 4).map((img: any) => (
                        <Image
                            key={img}
                            width={'100%'}
                            style={{
                                width: '100%',
                                borderRadius: 8,
                                objectFit: 'cover'
                            }}
                            src={getImageUrl(img)}
                        />
                    ))
                }

            </div>
        </Col>
    </Row>
}