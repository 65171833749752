import { useWeb3React } from "@web3-react/core"
import { useContractSigner, useContract } from "../../../Hook/useContract"
import HeronNftAbi from '../../../constants/heronnft.abi.json'
import Bep20Abi from '../../../constants/heronbep20.abi.json'
import { useCallback, useEffect, useState } from "react"
import { notification } from "antd"
import { useTokenBalance } from "./useTokenBalance"
import { useDispatch } from "react-redux"
import { SWAP_NFT_FAIL, SWAP_NFT_SUCCESS, SWAP_NFT_START } from "../../../actions/types"

export const useSwap = (
  {
    tokenAddress,
    contractAddress
  }: {
    tokenAddress: string
    contractAddress: string
  }
) => {
  const { account, library } = useWeb3React()
  // const [loading, setLoading] = useState(false)
  const HeronNftContractSigner = useContractSigner(contractAddress, HeronNftAbi)
  const bep20Contract = useContract(tokenAddress, Bep20Abi)
  const dispatch = useDispatch()
  const swap = useCallback(async (nftAmount: number) => {
    console.log('swapcalled')
    if (!account || !library || !HeronNftContractSigner || !bep20Contract) {
      notification.error({
        message: "Please connect to wallet"
      })
      return Promise.reject('Missing account or nftContract')
    }

    dispatch({
      type: SWAP_NFT_START,
      payload: null
    })

    const nftPrice = await HeronNftContractSigner.price()
    const balance = await bep20Contract.balanceOf(account)

    if (nftPrice * nftAmount > balance) {
      notification.error({
        message: "Insufficient balance"
      })
      return Promise.reject('Insufficient balance')
    }

    try {
      console.log('nftAmount', nftAmount)
      console.log('typeof nftAmount', typeof nftAmount)
      const tx = await HeronNftContractSigner.swap(nftAmount)
      await tx.wait(1)
      notification.success({
        message: "Swap successfully"
      })
      dispatch({
        type: SWAP_NFT_SUCCESS,
        payload: tx
      })
    } catch (error: any) {
      dispatch({
        type: SWAP_NFT_FAIL,
        payload: error
      })
      notification.error({
        message: error?.message || error
      })
    }
  }, [account, library, HeronNftContractSigner, bep20Contract])

  return { swap }

}
