import { useWeb3React } from "@web3-react/core"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import HeronNftAbi from '../../../constants/heronnft.abi.json'
import { useContract } from "../../../Hook/useContract"
import { SWAP_NFT_FAIL, SWAP_NFT_SUCCESS, SWAP_NFT_START } from "../../../actions/types"


export const useListenSwapEvent = (contractAddress: string) => {
  const { account, library } = useWeb3React()
  const [swapEvent, setSwapEvent] = useState<any>(null)
  const heronNftContract = useContract(contractAddress, HeronNftAbi)
  const dispatch = useDispatch()
  useEffect(() => {
    if (account && library && heronNftContract) {
      heronNftContract.on('MintHERON', (tokenId, user, timestamp) => {
        const event = {
          tokenId,
          user,
          timestamp
        }
        if (account.toLowerCase() == user.toLowerCase()) {
          setSwapEvent(event)
          // dispatch({
          //   type: SWAP_NFT_SUCCESS,
          //   payload: event
          // })
        }
      })
    }
    return () => {
      if (heronNftContract) {
        heronNftContract.removeAllListeners('MintHERON')
      }
    }
  }
    , [account, library, heronNftContract])
  return { swapEvent }
}
