import { Space } from "antd"
import { Outlet, Route, Routes } from "react-router-dom"

import './index.less'
import Header from "./Header"
import ResortImage from "./ResortImage"
import NoMatch from "../../components/NotMatch"
import ReservationGateway from "./ReservationGateway"
import PaymentGateway from "./PaymentGateway"

const OrderGateway = () => {
    return <Space size={16} className="order-gateway" direction='vertical'>
        <Header />
        <Outlet />
        <ResortImage />
    </Space>
}
const OrderGatewayWrapper = () => {
    return <>
        <Routes>
            <Route path="/" element={<OrderGateway />}>
                <Route index element={<ReservationGateway />} />
                <Route path="payment" element={<PaymentGateway />} />
                <Route path="*" element={<NoMatch />} />
            </Route>
        </Routes>
    </>
}
export default OrderGatewayWrapper;