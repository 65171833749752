import { useWeb3React } from '@web3-react/core'
import { Contract, ethers } from 'ethers'
import { useEffect, useState } from 'react'
import VoucherAbi from '../constants/voucher.abi.json'

let provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_URL)

const useVoucherContract = () => {
    const [contract, setContract] = useState<Contract | null>(null)
    let contractAddress = ethers.utils.getAddress(process.env.REACT_APP_VOUCHER_ADDRESS + '')

    useEffect(() => {
        contractAddress && setContract(new Contract(contractAddress, VoucherAbi, provider))
    }, [contractAddress])

    return contract
}
export const useVoucherContractSigner = () => {
    const [contract, setContract] = useState<Contract | null>(null)
    const { library } = useWeb3React()
    let contractAddress = ethers.utils.getAddress(process.env.REACT_APP_VOUCHER_ADDRESS + '')

    useEffect(() => {
        let signer = library && library.getSigner()
        signer && contractAddress && library && setContract(new Contract(contractAddress, VoucherAbi, signer))
    }, [library, contractAddress])

    return contract
}

export default useVoucherContract;