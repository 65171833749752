import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'

const POLLING_INTERVAL = 12000

const chainId = Number(process.env.REACT_APP_CHAIN_IDS)

const RPC_URLS: { [chainId: number]: string } = {
  56: 'https://bsc-dataseed.binance.org/' as string,
  97: 'https://data-seed-prebsc-1-s1.binance.org:8545/' as string
}

const injected = {
  connector: new InjectedConnector({
    supportedChainIds: chainId === 97 ? [97] : [56]
  }),
  image: '/images/metamask.svg',
  title: 'Metamask',
  desc: 'Connect to your Metamask Wallet'
}
const walletconnect = {
  connector: new WalletConnectConnector({
    rpc: chainId === 97 ? { 97: RPC_URLS[97] } : { 56: RPC_URLS[56] },
    qrcode: true,
    pollingInterval: POLLING_INTERVAL
  }),
  image: '/images/walletconnect.svg',
  title: 'WalletConnect',
  desc: 'Scan with WalletConnect to connect'
}


export default {
  injected,
  walletconnect
}