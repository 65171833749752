import { Button, Space, ButtonProps, Typography } from "antd"
import { useCallback, useMemo, useState } from "react"
const { Text } = Typography

type ButtonGroupProps = {
    direction?: 'horizontal' | 'vertical',
    buttons: { label: string, key: any, props: ButtonProps }[],
    defaultSelectedKey?: any,
    onChange?: (key: any) => void
}
const ButtonGroup = (props: ButtonGroupProps) => {
    const [selected, setSelected] = useState(props.defaultSelectedKey)
    const handleChange = useCallback((key: any) => {
        setSelected(key)
        props.onChange && props.onChange(key)
    }, [props])
    return <>
        <Space direction={props.direction}>
            {
                props.buttons.map(button => {
                    const isSelected = selected == button.key

                    return (
                        <Button style={{
                            maxWidth: 200,
                           
                        }} key={button.key} {...button.props} onClick={() => handleChange(button.key)} type={isSelected ? 'primary' : 'default'}>
                            <Text style={{
                                 color: isSelected ? 'white' : 'black'
                            }} ellipsis={{
                                tooltip: button.label
                            }} >{button.label}</Text>
                        </Button>
                    )
                })
            }
        </Space>
    </>
}

export default ButtonGroup