import { useWeb3React } from "@web3-react/core"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import HeronNft2023Abi from '../../../constants/heronnft2023.abi.json'
import { useContract } from "../../../Hook/useContract"

export const useListenTransferEvent = (contractAddress: string) => {
  const { account, library } = useWeb3React()
  const [transferEvent, setTransferEvent] = useState<any>(null)
  const [transferEventList, setTransferEventList] = useState<any[]>([])
  const dispatch = useDispatch()
  const heronNft2023Contract = useContract(contractAddress, HeronNft2023Abi)
  useEffect(() => {
    if (account && library && heronNft2023Contract) {
      heronNft2023Contract.on('Transfer', (from, to, tokenId) => {
        const event = {
          from,
          to,
          tokenId
        }
        if (account.toLowerCase() === from.toLowerCase()) {
          setTransferEvent(event)
          setTransferEventList((prev: any) => {
            return [...prev, event]
          })

          // dispatch({ type: 'TRANSFER_EVENT', payload: event })

        }

      })
    }
    return () => {
      if (heronNft2023Contract) {
        heronNft2023Contract.removeAllListeners('Transfer')
      }
    }
  }
    , [account, library, heronNft2023Contract])
  return { transferEvent }
}
