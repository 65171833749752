import axios from "axios";
import { useCallback } from "react"
import { sign } from "../../../helpers/crypto";
const useNewShortLink = () => {
    const newShortLink = useCallback(async (longLink: string) => {
        return axios.post(`${process.env.REACT_APP_BITLY_ENDPOINT}/api/url/shorten`, {
            longUrl: longLink,
            hash: sign({ longUrl: longLink })
        }).then((response) => response?.data?.data?.shortUrl);
    }, [])

    return { newShortLink }
}
export default useNewShortLink