import axios, { Method } from 'axios';
import { useEffect, useMemo, useRef, useState } from 'react';
import { notification } from 'antd';
import { useWeb3React } from '@web3-react/core';
import { useNavigate } from 'react-router-dom';
import usePrevious from '../../../Hook/usePrevious';
import { useListenClaimEvent } from './useListenClaimEvent';
import { getAddress } from 'ethers/lib/utils';
import { useSelector } from 'react-redux';
import { useListenTransferEvent } from './useListenTransferEvent';
import useSWRInfinite from "swr/infinite";
import heronnft2023Abi from "../../../constants/heronnft2023.abi.json";
import multicallAbi from "../../../constants/mullticall.abi.json";
import { Contract, ethers } from 'ethers';


const getList = (account: string, address: string, cursor?: string | null, signal?: any) => {

  var params = new URLSearchParams();
  params.append('token_addresses', address);
  params.append('chain', process.env.REACT_APP_MORALIS_NETWORK || '');
  params.append('format', 'decimal');
  params.append('normalizeMetadata', 'false');

  if (cursor) {
    params.append('cursor', cursor);
  }

  const options = {
    method: 'GET' as Method,
    url: `https://deep-index.moralis.io/api/v2/${account}/nft`,
    params: params,
    headers: { accept: 'application/json', 'X-API-Key': process.env.REACT_APP_MORALIS_API_KEY || '' },
    // signal: signal
  };

  return axios.request(options)


};

// const HERON_NFT_ADDRESS = getAddress(process.env.REACT_APP_HERON_NFT_ADDRESS + "");
// const HERON_NFT_2023_ADDRESS = getAddress(process.env.REACT_APP_HERON_NFT_2023_ADDRESS + "");

export const useGetListTokenOfNft = (address: string) => {
  const { account } = useWeb3React();
  const previousAccount = usePrevious(account)
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listTokenOfNft, setListTokenOfNft] = useState<any[]>([]);
  const [cursor, setCursor] = useState<string | undefined | null>(undefined);

  const controller = new AbortController();


  // const { swapEvent } = useListenSwapEvent(HERON_NFT_ADDRESS)
  // const { claimEvent } = useListenClaimEvent(HERON_NFT_2023_ADDRESS)

  useEffect(() => {
    setListTokenOfNft([]);
    setCursor(undefined)
  }, [account]);

  useEffect(() => {
    const fetchListTokenId = async (account: string | null | undefined, address: string) => {
      if (!account) return;
      try {
        setIsLoading(true);
        const response = await getList(account, address, cursor, controller.signal);
        setListTokenOfNft((pre) => [...pre, ...response.data.result]);
        // if (response.data.cursor) {
        //   setCursor(response.data.cursor)
        // }
        setIsLoading(false);
      } catch (error: any) {
        notification.error({
          message: error?.message || error.toString()
        });
        setIsLoading(false);
      }

    };

    fetchListTokenId(account, address);
    return () => {
      if (account !== previousAccount) {
        setCursor(undefined)
        controller.abort();
      }
    }
  }, [account, address]);

  return useMemo(() => ({ data: listTokenOfNft, isLoading }), [listTokenOfNft, isLoading])

}

export const useGetListTokenOfNft2023 = (address: string) => {
  const { account } = useWeb3React();
  const previousAccount = usePrevious(account)
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listTokenOfNft, setListTokenOfNft] = useState<any[]>([]);
  const [cursor, setCursor] = useState<string | undefined | null>(undefined);
  // const reFetch = useSelector((state: any) => state.transfer.reFetch)

  const { transferEvent } = useListenTransferEvent(address)

  const controller = new AbortController();

  useEffect(() => {
    setListTokenOfNft([]);
    setCursor(undefined)
  }, [account]);

  useEffect(() => {
    const fetchListTokenId = async (account: string | null | undefined, address: string) => {
      if (!account) return;
      try {
        setIsLoading(true);
        const response = await getList(account, address, cursor, controller.signal);
        // setListTokenOfNft((pre) => [...pre, ...response.data.result]);
        setListTokenOfNft(response.data.result);
        setCursor(response.data.cursor)
        setIsLoading(false);
      } catch (error: any) {
        notification.error({
          message: error?.message || error.toString()
        });
        setIsLoading(false);
      }

    };

    fetchListTokenId(account, address);
  }, [account, address, transferEvent]);

  return useMemo(() => ({ data: listTokenOfNft, isLoading }), [listTokenOfNft, isLoading])

}

const HERON_NFT_2023_ADDRESS = ethers.utils.getAddress(process.env.REACT_APP_HERON_NFT_2023_ADDRESS + "");
const MULTICALL_CONTRACT_ADDRESS = ethers.utils.getAddress(process.env.REACT_APP_MULTICALL_CONTRACT_ADDRESS + "");


const getMulticallClaimInfo = async (listTokenId: (string | number)[]) => {

  const heronnft2023Contract = new Contract(HERON_NFT_2023_ADDRESS, heronnft2023Abi, new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_URL_2 || ''));
  const multicallContract = new Contract(MULTICALL_CONTRACT_ADDRESS, multicallAbi, new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_URL_2 || ''));

  const calls = listTokenId.map((tokenId: string | number) => {
    const callData = heronnft2023Contract.interface.encodeFunctionData("tokenIds", [tokenId]);
    return {
      target: HERON_NFT_2023_ADDRESS.toLowerCase(),
      allowFailure: true,
      callData,
    };
  });
  const results = await multicallContract.aggregate3(calls);
  const decodeResult = results.map(
    (
      {
        returnData,
        success,
      }: {
        returnData: any;
        success: boolean;
      },
      i: any
    ) => {
      const deCodeData = heronnft2023Contract.interface.decodeFunctionResult("tokenIds", returnData);
      return Array.isArray(deCodeData) && deCodeData.length === 1 ? deCodeData[0] : deCodeData
    }
  );
  const claimInfo = decodeResult.map((item: any, i: any) => {
    return {
      token_id: listTokenId[i],
      isClaimed: item,
    };
  });
  return claimInfo;
}

export function useGetListTokenOfNftSwrInfinite({
  tokenAddress,
  pageSize = 100,
}: {
  tokenAddress: string
  pageSize?: number
}) {
  const { account } = useWeb3React();
  const fetcher = async (cursor: any) => {
    if (cursor[0] === "undefined") {
      cursor = [undefined, "nft", account]
    }

    const res = await getList(account as string, tokenAddress, cursor[0]);
    const result = res.data.result;
    const listTokenId = result.map((item: any) => item.token_id);
    const claimInfo = await getMulticallClaimInfo(listTokenId);
    const returnEd = claimInfo.map((item: any, i: any) => {
      return {
        ...item,
        cursor: res.data.cursor,
      };
    }
    );
    return returnEd;
  }

  const getKey = (pageIndex: number, previousPageData: any) => {
    if (previousPageData && !previousPageData.length) return null // reached the end
    // first page, we don't have `previousPageData`
    if (pageIndex === 0) return ["undefined", "nft", account]
    // add the cursor to the API endpoint
    return [previousPageData[previousPageData.length - 1].cursor, "nft", account]
  }

  const { data, error, mutate, size, setSize, isValidating } = useSWRInfinite(account ? getKey : null as any, fetcher, {
    revalidateOnFocus: false,
    revalidateFirstPage: false,
    revalidateAll: false,
    revalidateIfStale: false,
  })

  const PAGE_SIZE = pageSize

  const nftData = data ? [].concat(...data) : []
  const isLoadingInitialData = !data && !error
  const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === 'undefined')
  const isEmpty = data?.[0]?.length === 0
  const isReachingEnd = isEmpty || (data && data[data.length - 1]?.length < PAGE_SIZE)
  const isRefreshing = isValidating && data && data.length === size
  return {
    data: nftData as any[],
    size,
    setSize,
    isLoadingMore,
    isReachingEnd,
    isRefreshing,
    mutate
  }
}

export function useGetListTokenOfNft2023SwrInfinite({
  tokenAddress,
  pageSize = 100,
}: {
  tokenAddress: string
  pageSize?: number
}) {
  const { account } = useWeb3React();
  const fetcher = async (cursor: any) => {
    if (cursor[0] === "undefined") {
      cursor = [undefined, "nft2023", account, 0]
    }
    const res = await getList(account as string, tokenAddress, cursor[0]);
    const result = res.data.result.map((item: any) => {
      return {
        ...item,
        cursor: res.data.cursor
      }
    });
    return result
  }

  const getKey = (pageIndex: number, previousPageData: any) => {
    if (previousPageData && previousPageData[previousPageData.length - 1].cursor === null) return null // reached the end

    // first page, we don't have `previousPageData
    if (pageIndex === 0) return ["undefined", "nft2023", account]
    // add the cursor to the API endpoint

    return [previousPageData[previousPageData.length - 1].cursor, "nft2023", account]
  }

  const { data, error, mutate, size, setSize, isValidating } = useSWRInfinite(account ? getKey : null as any, fetcher, {
    revalidateOnFocus: false,
    revalidateFirstPage: false,
    revalidateAll: false,
    revalidateIfStale: false,
  })



  const PAGE_SIZE = pageSize

  // const data = rawData?.map((item: any) => item.nftData)

  const nftData = data ? [].concat(...data) : []
  const isLoadingInitialData = !data && !error
  const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === 'undefined')
  const isEmpty = data?.[0]?.length === 0
  const isReachingEnd = isEmpty || (data && data[data.length - 1]?.length < PAGE_SIZE)
  const isRefreshing = isValidating && data && data.length === size
  return {
    data: nftData as any[],
    size,
    setSize,
    isLoadingMore,
    isReachingEnd,
    isRefreshing,
    mutate
  }
}