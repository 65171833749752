import { Space, Typography } from "antd"

const { Text, Title } = Typography

export const InfoItem = (props: any) => {
    return <Space style={{
        width: '100%',
        justifyContent: 'space-between',
        paddingLeft: 16,
        paddingRight: 16
    }}>
        <Text strong={props.strong}>{props.label}</Text>
        {
            props.children
        }
    </Space>
}