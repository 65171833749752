import { DatePicker, DatePickerProps } from "antd";
import { Moment } from "moment";
import { useRef, useState } from "react";

const { RangePicker }: any = DatePicker;

type RangeValue = [Moment | null, Moment | null] | null;

const RangePickerLimit = (props: {
    onChange: (value: [Moment, Moment]) => void,
    defaultValue: [Moment, Moment] | null,
    maxRange: Number,
    minRange: number,
    disabledDate?: (date: Moment | null) => boolean,
    minDate?: Moment,
    placeholder?: [string, string],
    bordered?: boolean
}) => {
    const [dates, setDates] = useState<RangeValue>(props.defaultValue);
    const [hackValue, setHackValue] = useState<RangeValue>(props.defaultValue);
    const [value, setValue] = useState<RangeValue>(props.defaultValue);
    const dic = useRef(props.defaultValue)
    const disabledDate = (current: Moment) => {
        if (!dates) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > props.maxRange;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > props.maxRange;

        const tooShort = (dates[0] && current.diff(dates[0], 'days') < props.minRange) || (dates[1] && dates[1].diff(current, 'days') < props.minRange)

        const tooPast = props.minDate && current.diff(props.minDate) < 0

        return !!tooEarly || !!tooLate || !!tooShort || !!tooPast
    };

    const onOpenChange = (open: boolean) => {
        if (open) {
            setHackValue([null, null]);
            setDates([null, null]);
        } else {
            setHackValue(null);
            dic.current && props?.onChange(dic.current)
        }
    };
    return (
        <RangePicker
            placement='topLeft'
            value={hackValue || value}
            disabledDate={disabledDate}
            onCalendarChange={(val:any) => {
                setDates(val)
            }}
            onChange={(val: any) => {
                dic.current = (val || props.defaultValue)
                setValue(val || props.defaultValue)
            }}
            placeholder={props.placeholder}
            onOpenChange={onOpenChange}
            bordered={props.bordered}
        />
    );
};


export default RangePickerLimit