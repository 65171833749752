import { Col, Row, Image, Typography, Select, DatePicker, Popover, Button } from "antd"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"

import UserIcon from '../../user.png'
import CalendarIcon from '../../calendar.png'
import ResortIcon from '../../resort.png'
import { useTranslation } from "react-i18next"
import { Form } from './BoxSearch'
import { Moment } from "moment"
import { moment } from '../../helpers/time'

const { Text, Title } = Typography
const { Option } = Select

const { } = DatePicker

type InputSearchProps = {
    icon: React.ReactNode,
    input?: React.ReactNode,
    title: string | React.ReactNode
}
const InputSearch = ({ icon, title, input }: InputSearchProps) => {
    return <Row className="input-search-wrapper">
        <Col className="input-search-icon" span={6}>
            {icon}
        </Col>
        <Col span={18}>
            <Row>
                <Title level={3}>{title}</Title>
            </Row>
            <Row>
                {input}
            </Row>
        </Col>
    </Row>
}
type BoxSearchMobileProps = {
    setQuery: Function,
    defaultResort: string,
    resorts: any[],
    query: any,
    disabled: any,
    loading: any,
    onClick: any
}

const BoxSearchMobile = (props: BoxSearchMobileProps) => {
    const [openCheckin, setOpenCheckin] = useState(false)
    const [openCheckout, setOpenCheckout] = useState(false)
    const refCheckIn = useRef<any>()
    const refCheckOut = useRef<any>()
    const { t } = useTranslation()
    const { adult, children } = props.query

    const defaultValueTravelers = useMemo(() => {
        return { adult, children }
    }, [])

    const disabledCheckIn = useCallback((date: Moment) => {
        date = date.set({ "hour": process.env.REACT_APP_CHECKOUT as any, "minute": 0, "second": 0, "millisecond": 0 })

        let tooOverMaxrange = props.query.checkOut && (Math.abs(date.diff(moment(props.query.checkOut), 'day')) > 30) // validate max range checkin-checkout

        let tooConflict = props.query.checkOut && (date.diff(moment(props.query.checkOut), 'day') >= 0) // Validate with checkout

        let tooLate = date.diff(moment().endOf('date')) <= 0 // Validate with today

        return tooLate || tooConflict || tooOverMaxrange
    }, [props.query])

    const disabledDateCheckOut = useCallback((date) => {

        date = date.set({ "hour": process.env.REACT_APP_CHECKIN as any, "minute": 0, "second": 0, "millisecond": 0 })

        let tooOverMaxrange = props.query.checkIn && (Math.abs(date.diff(moment(props.query.checkIn), 'day')) > 30) // validate max range checkin-checkout

        let tooConflict = props.query.checkIn && (date.diff(moment(props.query.checkIn), 'day') < 1) // Validate with checkin

        let tooLate = date.diff(moment().endOf('date'), 'day') < 1 // Validate with today

        return tooLate || tooConflict || tooOverMaxrange
    }, [props.query])

    useEffect(() => {
        !openCheckin && refCheckIn.current?.blur()
    }, [openCheckin])

    useEffect(() => {
        !openCheckout && refCheckOut.current?.blur()
    }, [openCheckout])

    return <Row style={{
        padding: 8
    }}>
        <Col style={{
            marginBottom: 16
        }} span={24}>
            <InputSearch
                input={
                    <Select placement='topLeft'
                        placeholder={<Text className='subLabel'>{t('home.pickResort')}</Text>}
                        onChange={(value: any) => {
                            props.setQuery({ ...props.query, resort: value })
                        }}
                        showArrow={false}
                        defaultValue={props.defaultResort || []}
                        style={{
                            width: '100%',
                            padding: 0,
                            marginLeft: -11
                        }}

                        bordered={false}
                    >
                        {props.resorts?.map((op: any) => <Option value={op._id} key={op._id} >{op.name}</Option>)}

                    </Select>
                }
                icon={<Image width={24} preview={false} src={ResortIcon} />} title="Resort" />
        </Col>

        <Col style={{
            marginBottom: 16
        }} span={24}>
            <InputSearch
                input={
                    <DatePicker
                        inputReadOnly={true}
                        onKeyDown={(e) => e.preventDefault()}
                        key={props.query.checkIn}
                        value={props.query.checkIn && moment(props.query.checkIn) || null}
                        dateRender={current => {
                            const style: React.CSSProperties = {};
                            if (props.query.checkIn && props.query.checkOut && current.isBetween(moment(props.query.checkIn).endOf('date'), moment(props.query.checkOut).endOf('date'))) {
                                style.color = 'white';
                                style.backgroundColor = '#329696';
                            } else if (props.query.checkOut && current.endOf('date').isSame(moment(props.query.checkOut).endOf('date'))) {
                                style.color = 'white';
                                style.backgroundColor = '#329696';
                            }

                            return (
                                <div className="ant-picker-cell-inner" style={style}>
                                    {current.date()}
                                </div>
                            );
                        }}
                        ref={refCheckIn}
                        onFocus={() => setOpenCheckin(true)}
                        onBlur={() => setOpenCheckin(false)}
                        open={openCheckin}
                        disabledDate={disabledCheckIn}
                        style={{
                            marginLeft: -11
                        }}
                        onChange={(value) => {
                            setOpenCheckin(false)
                            value && !props.query.checkOut && setOpenCheckout(true)
                            if (!value) {
                                props.setQuery({
                                    ...props.query,
                                    checkIn: null,
                                    checkOut: null
                                })
                            } else {
                                value = value.set({ "hour": process.env.REACT_APP_CHECKIN as any, "minute": 0, "second": 0, "millisecond": 0 })
                                props.setQuery({
                                    ...props.query,
                                    checkIn: value?.valueOf()
                                })
                            }

                        }}
                        placement='topRight'
                        placeholder="Add date"
                        bordered={false}
                    />
                }
                icon={<>
                    <Image width={24}
                        preview={false} src={CalendarIcon} />
                </>}
                title="Checkin" />
        </Col>
        <Col style={{
            marginBottom: 16
        }} span={24}>
            <InputSearch
                input={
                    <DatePicker
                        inputReadOnly={true}
                        onKeyDown={(e) => e.preventDefault()}
                        key={props.query.checkOut}
                        value={props.query.checkOut && moment(props.query.checkOut) || null}
                        ref={refCheckOut}
                        dateRender={current => {
                            const style: React.CSSProperties = {};
                            if (props.query.checkIn && props.query.checkOut && current.isBetween(moment(props.query.checkIn).startOf('date'), moment(props.query.checkOut).startOf('date'))) {
                                style.color = 'white';
                                style.backgroundColor = '#329696';
                            } else if (props.query.checkIn && current.endOf('date').isSame(moment(props.query.checkIn).endOf('date'))) {
                                style.color = 'white';
                                style.backgroundColor = '#329696';
                            }
                            return (
                                <div className="ant-picker-cell-inner" style={style}>
                                    {current.date()}
                                </div>
                            );
                        }}
                        onFocus={() => setOpenCheckout(true)}
                        onBlur={() => setOpenCheckout(false)}
                        open={openCheckout}
                        disabledDate={disabledDateCheckOut}
                        placement='topRight'
                        placeholder="Add date"
                        bordered={false}
                        onChange={(value) => {
                            setOpenCheckout(false)
                            if (!value) {
                                props.setQuery({
                                    ...props.query,
                                    checkIn: null,
                                    checkOut: null
                                })
                            } else {
                                value = value.set({ "hour": process.env.REACT_APP_CHECKOUT as any, "minute": 0, "second": 0, "millisecond": 0 })
                                props.setQuery({
                                    ...props.query,
                                    checkOut: value?.valueOf()
                                })
                            }

                        }}

                        style={{
                            marginLeft: -11
                        }}
                    />
                }
                icon={<Image width={24} preview={false} src={CalendarIcon} />}
                title="Checkout" />
        </Col>
        <Col style={{
            marginBottom: 16
        }} span={24}>
            <Popover
                onVisibleChange={open => {
                    setOpenCheckout(false)
                }}
                trigger='click'
                placement='top'
                content={<Form
                    onChangeAdult={(adult: any) => props.setQuery({
                        ...props.query,
                        adult
                    })}
                    onChangeChildren={(children: any) => props.setQuery({
                        ...props.query,
                        children
                    })}
                    defaultValue={defaultValueTravelers}
                />}
            >
                <div>
                    <InputSearch
                        input={<Text
                            type='secondary'
                            // className='subLabel left'
                            ellipsis={{ tooltip: `${adult} ${t('common.adult')} * ${children} ${t('common.children')}` }}
                        >
                            {`${adult} ${t('common.adult')} * ${children} ${t('common.children')}`}
                        </Text>}
                        icon={<Image width={24} preview={false} src={UserIcon} />}
                        title={'Add travelers'} />
                </div>
            </Popover>
        </Col>
        <Col style={{ textAlign: 'center' }} span={24}>
            <Button style={{ width: '100%', marginTop: 16 }} disabled={props.disabled} loading={props.loading} onClick={props.onClick} shape='round' type='primary'>
                {t('common.searchUppercase')}
            </Button>
        </Col>
    </Row >
}
export default BoxSearchMobile