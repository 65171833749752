import { useWeb3React } from "@web3-react/core"
import { notification } from "antd"
import { useCallback, useEffect, useState } from "react"
import { useLoadingModal } from "../../../Hook/useLoading"
import { useNftContractSigner } from "../../../Hook/useNftContract"
import useVoucherContract from "../../../Hook/useVoucherContract"

export const useApprovalVoucher = () => {
    const { library, account } = useWeb3React()

    const [isApproval, setApproval] = useState(false)
    const { showLoading, hideLoading } = useLoadingModal()
    const voucherContract = useVoucherContract()
    const nftContractSigner = useNftContractSigner()

    const handleApproval = useCallback(async () => {
        if (!account || !library || !voucherContract || !nftContractSigner) {
            notification.error({
                message: "Please connect to wallet"
            })
            return Promise.reject('Missing account or voucherContract or nftContractSigner')
        }
        try {
            showLoading()
            const tx = await nftContractSigner.setApprovalForAll(voucherContract.address, true)
            await tx.wait(1)
            hideLoading()
            setApproval(true)
        } catch (error: any) {
            hideLoading()
            notification.error({
                message: error?.message || error.toString()
            })
        }
    }, [account, library, voucherContract, nftContractSigner, showLoading, hideLoading])

    useEffect(() => {
        if (nftContractSigner && account && nftContractSigner) {
            account && nftContractSigner.isApprovedForAll(account, voucherContract?.address).then((isApproval: any) => {
                setApproval(isApproval)
            })
        }
    }, [account, library, voucherContract, nftContractSigner])

    return { isApproval, handleApproval }
}