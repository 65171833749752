import Axios from "../helpers/axios"

const fetchResort = () => {
    return Axios
        .get('api/resort/all')
        .then((response) => {
            return response?.data?.data;
        });
}
const fetchRoomTypeByResort = (id: any) => {
    return Axios.get('api/roomType/resort/' + id).then(res => res?.data?.data || [])
}
export default {
    fetchResort,
    fetchRoomTypeByResort
}