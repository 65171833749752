import { useEffect, useState } from "react"
import { getProvider } from "../../../helpers/crypto"
import { useQuery } from "../../../Hook/query"
let provider = getProvider()
const useTransaction = () => {
    const { params } = useQuery()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
        if (!params.transactionHash) return
        const exec = async () => {
            try {
                setLoading(true)
                let tx = await provider.getTransaction(params.transactionHash);
                console.info('Fetch tx', tx)
                for (let index = 0; index < 5; index++) {
                    if (tx != null) continue
                    console.log('Try get transaction at ')
                    await new Promise(resolve => setTimeout(() => {
                        resolve(true)
                    }, 3000))

                    tx = await provider.getTransaction(params.transactionHash);
                }
                await tx.wait(1)
                setLoading(false)
            } catch (error: any) {
                console.log('useTransaction',error)
                setError(error?.message || error)
                setLoading(false)
            }
        }
        exec()
    }, [params.transactionHash])
    return { loading, error }
}
export default useTransaction