import { Button, Col, Divider, Row, Space, Typography, Image, Alert } from "antd"
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation, Trans } from 'react-i18next'

import { formatMoney } from "../../../helpers/number";

import { useQuery } from "../../../Hook/query";

import UserIcon from '../../../user.png'
import CalendarIcon from '../../../calendar.png'
import { queryStringify } from "../../../helpers/url";
import usePrice from "../Hooks/usePrice";
import Timeline from "../Timeline";
import { RoomBooked } from "../../../Types/Room";
import Paragraph from "antd/lib/typography/Paragraph";

const { Text } = Typography
export const InfoItem = (props: any) => {
    return <Space style={{
        width: '100%',
        justifyContent: 'space-between',
        paddingLeft: 16,
        paddingRight: 16
    }}>
        <Text strong={props.strong}>{props.label}</Text>
        {
            props.children
        }
    </Space>
}
const OrderInfo = () => {
    const { params: { checkIn, checkOut, adult, children, resort } } = useQuery()

    const rooms: RoomBooked[] = useSelector((state: any) => state.reservation?.rooms || [])
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { checkInNumber, checkOutNumber } = useMemo(() => ({
        checkInNumber: Number(checkIn),
        checkOutNumber: Number(checkOut)
    }), [checkIn, checkOut])


    const priceDetail = usePrice(rooms, checkInNumber, checkOutNumber)

    const totalAmountRoom = useMemo(() => {
        if (!rooms?.length) return 0
        return rooms.reduce((ret: number, room: any) => ret + Number(room.amount), 0)
    }, [rooms])

    const disabled = useMemo(() => {
        return !rooms?.length
    }, [rooms])

    const warning = useMemo(() => {
        if (!rooms?.length) return <Trans i18nKey="reservation.noRoomSelected" />

        const { maxAdult } = rooms.reduce((ret: any, room: any) => {
            return {
                maxAdult: ret.maxAdult + room.maxAdult * room.amount,
            }
        }, { maxAdult: 0 })

        if (maxAdult >= adult) return null

        let counts = adult - maxAdult;

        return maxAdult >= adult ? <Trans i18nKey="reservation.missChildren">
            {{ counts }}
        </Trans> :
            <Trans i18nKey="reservation.missAdult">
                {{ counts }}
            </Trans>
    }, [rooms, adult])

    const handleConfirm = useCallback(() => {
        navigate(queryStringify('/rooms/payment', {
            resort,
            adult,
            children,
            checkIn,
            checkOut,
            amount: totalAmountRoom
        }))
    }, [totalAmountRoom, navigate, checkIn, checkOut, adult, children, resort])
    const renderTotal = useCallback(() => {
        return formatMoney(priceDetail.totalPrice)

    }, [priceDetail.totalPrice])
    return (
        <Space className="heron-reservation-container" direction='vertical'>
            <Space className="heron-order-gateway-atribute" direction='vertical'>
                <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                        <Space className="heron-reservation-atribute-item">
                            <Image preview={false} src={CalendarIcon} />
                            <Space direction='vertical' size={2}>
                                <Text disabled className='font-12'>{t('common.checkIn')}</Text>
                                <Text strong>{moment(Number(checkInNumber)).format('L')}</Text>
                            </Space>
                        </Space>
                    </Col>
                    <Col span={1}>
                        <Divider type="vertical" />
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                        <Space className="heron-reservation-atribute-item">
                            <Image preview={false} src={CalendarIcon} />
                            <Space style={{ textAlign: 'left' }} direction='vertical' size={2}>
                                <Text disabled className='font-12'>{t('common.checkOut')}</Text>
                                <Text strong>{moment(Number(checkOutNumber)).format('L')}</Text>
                            </Space>
                        </Space>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                        <Space className="heron-reservation-atribute-item">
                            <Image preview={false} src={UserIcon} />
                            <Space style={{ textAlign: 'left' }} direction='vertical' size={2}>
                                <Text disabled className='font-12'>{t('common.adult')}</Text>
                                <Text strong>{adult}</Text>
                            </Space>
                        </Space>
                    </Col>
                    <Col span={1}>
                        <Divider type="vertical" />
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                        <Space className="heron-reservation-atribute-item">
                            <Image preview={false} src={UserIcon} />
                            <Space style={{ textAlign: 'left' }} direction='vertical' size={2}>
                                <Text disabled className='font-12'>{t('common.children')}</Text>
                                <Text strong>{children}</Text>
                            </Space>

                        </Space>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                        <Space className="heron-reservation-atribute-item">
                            <Image preview={false} src={UserIcon} />
                            <Space style={{ textAlign: 'left' }} direction='vertical' size={2}>
                                <Text disabled className='font-12'>{t('common.roomAmounts')} </Text>
                                <Text strong>{totalAmountRoom}</Text>
                            </Space>

                        </Space>
                    </Col>

                </Row>
            </Space>
            <Text type='warning'>
                {warning}
            </Text>
            <Alert
                // message="Policy"
                description={
                    <Space direction='vertical'>
                        <Text strong >1. Child policy:</Text>
                        <Paragraph>
                            <ul>
                                <li>
                                    <Text>Children under 3 years old: Free of charge.</Text>
                                </li>
                                <li>
                                    <Text>Children from 4 to 12 years old: 50% off.</Text>
                                </li>
                                <li>
                                    <Text>Children above 12 years old: full charge.</Text>
                                </li>
                            </ul>
                        </Paragraph>
                        <Text strong>2. Extra people</Text>
                        <Paragraph>
                            <ul>
                                <li>
                                    <Text>Extra adult: 600,000 vnd / person</Text>
                                </li>
                                <li>
                                    <Text>Extra child (4 - 12 years old): 400,000 vnd / person</Text>
                                </li>
                            </ul>
                        </Paragraph>
                        <Text strong>
                            3. Checkin-Checkout policy:
                        </Text>
                        <Paragraph>
                            <ul>
                                <li>
                                    <Text>Early check-in (before 1:30 pm) & late check-out (until 6:00 pm) are subject to availability and be charged at 50% of the listed price</Text>
                                </li>
                                <li>
                                    <Text>Check-out after 6pm also will be subject to full listed price</Text>
                                </li>
                            </ul>
                        </Paragraph>
                        <Text strong type='warning'>
                            The room rate does not include the cost of children, this surcharge will be paid in cash at the resort.
                        </Text>
                    </Space>
                }
                type="success"
            />
            <Button
                disabled={disabled || !!warning}
                onClick={handleConfirm}
                shape='round'
                type='primary'
                style={{
                    width: '100%',
                    marginTop: 32
                }}>
                {t('common.confirm')}
            </Button>
            <Space direction='vertical'
                style={{
                    width: '100%',
                    marginTop: 32
                }}
            >
                <Timeline priceDetail={priceDetail} />
                <div className="heron-order-gateway-reservation-total">
                    <InfoItem strong={true} label="Total" >
                        <Text strong>{renderTotal()}</Text>
                    </InfoItem>
                </div>
            </Space>
        </Space>
    )
}

export default OrderInfo;