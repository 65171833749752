import { useWeb3React } from '@web3-react/core'
import { BigNumber, Contract } from 'ethers'
import { useCallback, useEffect, useState } from 'react'
import Bep20Abi from '../../../constants/heronbep20.abi.json'
import { useContract } from '../../../Hook/useContract'
import { notification } from "antd"
import { useSelector } from 'react-redux'
import useBlockNumber from '../../../Hook/useBlockNumber'

export interface BalanceProps {
  value: BigNumber
  decimals: number
}

export const useTokenBalance = (address: string) => {
  const { library, account } = useWeb3React()
  const bep20Contract = useContract(address, Bep20Abi)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [balance, setBalance] = useState<BalanceProps>({ value: BigNumber.from(0), decimals: 18 })
  const reFetch = useSelector((state: any) => state.swap.reFetch)
  const blockNumber = useBlockNumber()

  const fetchBalance = useCallback(async () => {
    const getBalance = async () => {
      if (!account || !library || !bep20Contract) {
        notification.error({
          message: "Please connect to wallet"
        })
        return Promise.reject('Missing account or nftContract')
      }
      try {
        const balance = await bep20Contract.balanceOf(account)
        const decimals = await bep20Contract.decimals()
        return { value: BigNumber.from(balance), decimals: decimals }
      } catch (error: any) {
        notification.error({
          message: error?.message || error.toString()
        })
        return { value: BigNumber.from(0), decimals: 18 }
      }
    }
    const balance = await getBalance()

    setBalance(balance)

  }, [account, library, bep20Contract])

  useEffect(() => {
    if (account && library && bep20Contract) {
      fetchBalance()
    }
  }, [account, library, bep20Contract, fetchBalance, reFetch,blockNumber])

  return { balance, isBalanceLoading: isLoading }
}


