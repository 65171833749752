import { BigNumber } from "ethers"
import { formatEther, } from "ethers/lib/utils"
import { BalanceProps } from "../Pages/Swap/Hooks/useTokenBalance"

export const formatMoney = (value: any) => {
    value = parseInt(value)
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/**
 * Group array number to array of array 
 * @param numbers 
 * @param count 
 * @returns 
 */
export function groupNumber(ids: number[], count: number, numberOfGroup: number) {
    let ret = []
    let length = numberOfGroup
    for (let index = 0; index < count; index++) {
        ret.push(ids.slice(index * length, index * length + length))
    }
    return ret
}

export function formatBalance({ value, decimals }: BalanceProps) {
    return Number(formatEther(value))
}