import { queryStringify } from "../helpers/url"
import Axios from "../helpers/axios"
export interface ReservationParams {
    checkIn: string,
    checkOut: string,
    orderId: string,
    secureHash: string,
    totalPrice: number,
    invoice: {
        adult: string,
        children: string,
        email: string,
        fullname: string,
        message: string,
        phone: string,
    },
    room: { amount: number, roomId: string }[]
}
const create = (params: ReservationParams) => {
    return Axios.post('api/reservation', params).then(res => res?.data?.data)
}
export interface IpnParams {
    amount:string,
    extraData:string,
    message:string,
    orderId:string,
    orderInfo:string,
    orderType:string,
    partnerCode:string,
    payType:string,
    requestId:string,
    responseTime:string,
    resultCode:string,
    signature:string,
    transId:string,
}
const ipn = (params: IpnParams) => {
    console.info('ipn', params)
    return Axios.post('api/payment/urlReturn', params).then(res => res?.data?.data)
}

export interface PaymentUrlParams {
    orderDescription: string,
    amount: number
}
const getPaymentUrl = (params: PaymentUrlParams) => {
    return Axios.post('api/payment/url', params).then(res => res?.data?.data)
}

const cancelPayment = (params: any) => {
    const url = queryStringify('api/payment/cancel', params)
    return Axios.get(url).then(res => res?.data?.data)
}

export default {
    create,
    ipn,
    getPaymentUrl,
    cancelPayment
}