import { Row, Col, Divider, Space, Button, Typography, Select, Spin, List } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { orderBy } from 'lodash';

import './index.less'
import CardRoom from './CardRoom'

import { searchRoom } from '../../actions/room'

import { useQuery } from '../../Hook/query'
import { Room } from '../../Types/Room';

const { Title, Text } = Typography;

const RoomList = (props: any) => {
  const { params: { resort, adult, checkIn, checkOut } } = useQuery()
  const rooms: Room[] = useSelector((state: any) => state?.room?.data || [])
  const loading = useSelector((state: any) => state?.room?.loading)


  const dispatch = useDispatch()

  const data = useMemo(() => {
    return orderBy(rooms, 'price')
  }, [rooms])

  const renderContent = useCallback(() => {
    if (loading) {
      return <Row style={{ width: '100%' }} justify='center'><Title level={4}> <Spin size='large' /></Title></Row>
    }
    return <>
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={room => (
          <List.Item>
            <CardRoom adult={adult} checkIn={checkIn} checkOut={checkOut} room={room} />
          </List.Item>
        )}
      />
    </>
  }, [data, loading])
  useEffect(() => {
    dispatch(searchRoom({
      resort,
      adult,
      checkIn,
      checkOut
    }))
  }, [dispatch, resort, adult, checkIn, checkOut])

  return (
    <>
      {renderContent()}
    </>
  )

}

export default RoomList;