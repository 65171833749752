import React, { useEffect, useImperativeHandle, useLayoutEffect, useRef } from 'react'
import { useWeb3React } from "@web3-react/core"
import { useState } from "react"
import { Web3ReactModal } from "web3-react-modal"
import { ethers } from 'ethers'

import connectors from '../constants/connectors'
import { CONNECTED } from '../constants/index'
import { notification } from 'antd'

const WalletConnect = React.forwardRef((props, ref) => {
    const [visible, setVisible] = useState<any>(false)
    const { activate, error, deactivate, chainId } = useWeb3React()
    useImperativeHandle(
        ref,
        () => ({
            setVisible
        }),
        [],
    )

    useEffect(() => {
        let initConnector = localStorage.getItem(CONNECTED)
        let CONNECTORS: any = connectors
        if (initConnector) {
            let names = Object.keys(CONNECTORS);
            let connectorName: any = names.find(n => n === initConnector)
            let connector = connectorName ? CONNECTORS[connectorName]?.connector : null

            if (connector) {
                activate(connector, (error) => {
                    console.info('error', error.toString())
                    notification.error({
                        message: error.toString()
                    })
                    deactivate();
                    localStorage.removeItem(CONNECTED)
                })
            }
        }
    }, [])
    return (
        <>
            <Web3ReactModal
                visible={visible}
                setVisible={setVisible}
                providerOptions={connectors}
                onConnect={(connector: any, name: any) => {
                    activate(connector, (error) => {
                        localStorage.removeItem(CONNECTED)
                        console.info('error', error.toString())
                        notification.error({
                            message: error.toString()
                        })
                        deactivate()
                        // Switch network
                        // window.ethereum ?
                        //     window.ethereum.request({ method: "wallet_switchEthereumChain", params: [{ chainId: ethers.BigNumber.from(Number(process.env.REACT_APP_CHAIN_IDS)).toHexString() }] }).then(() => {
                        //         activate(connector, () => {
                        //             localStorage.removeItem(CONNECTED)
                        //             notification.error({
                        //                 message: error.toString()
                        //             })
                        //             deactivate()
                        //         }).then(() => {
                        //             localStorage.setItem(CONNECTED, name)
                        //         })
                        //     }).catch((err: any) => {
                        //         console.error('error', err)
                        //     })
                        //     : notification.error({
                        //         message: 'Please install Wallet'
                        //     })
                    }).then(() => {
                        localStorage.setItem(CONNECTED, name)
                    })
                }}
            />
        </>
    )
})
export default WalletConnect;