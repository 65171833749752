import * as React from "react"

function ArrowUpIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.265 13.91a1 1 0 01.057-1.414l3.5-3.23a1 1 0 011.356 0l3.5 3.23a1 1 0 11-1.356 1.47L16.5 12.284V22a1 1 0 11-2 0v-9.716l-1.822 1.682a1 1 0 01-1.413-.057z"
        fill="#329696"
      />
      <rect
        x={1}
        y={31}
        width={30}
        height={30}
        rx={15}
        transform="rotate(-90 1 31)"
        stroke="#329696"
        strokeWidth={2}
      />
    </svg>
  )
}

export default ArrowUpIcon
