import { Button, Space } from "antd"
import { LeftOutlined } from '@ant-design/icons';
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { queryStringify } from "../../helpers/url";

import { useQuery } from "../../Hook/query";

const Header = () => {
    const { params }: any = useQuery()
    const { t } = useTranslation();

    const navigate = useNavigate();

    const goHome = useCallback(() => {
        navigate(queryStringify('/', params), { state: {} })
    }, [navigate, params])

    return <Space size={24} className='room-list-container' direction='vertical' >
        <Button onClick={goHome} icon={<LeftOutlined />} shape="round" >
            {t('common.goHome')}
        </Button>
    </Space>
}
export default Header;