import { FETCH_ROOM_SUCCESS, FETCH_ROOM_DETAIL_SUCCESS, FETCH_ROOM_START, RESERVATION_UPDATE_ROOMS_INFO } from '../actions/types'
import RoomService from '../services/room'
import { Room, RoomBooked } from '../Types/Room'
export const searchRoom = (query: any) => (dispatch: (arg0: any) => void, getState: () => any) => {
    let globalState = getState()

    dispatch({
        type: FETCH_ROOM_START,
    })
    return RoomService.searchRoom(query).then((rooms: Room[]) => {
        dispatch({
            type: FETCH_ROOM_SUCCESS,
            payload: rooms,
        });
        dispatch({
            type: RESERVATION_UPDATE_ROOMS_INFO,
            payload: rooms // Rooms of resort
        })

        return Promise.resolve();
    }, (error) => {
        dispatch({
            type: FETCH_ROOM_SUCCESS,
            payload: [],
        });
        console.error(error?.message)
    })
}
