import { Modal, Space, Spin, Typography } from "antd"
import { useCallback, useRef } from "react"
const { Text } = Typography
type Config = {
    message?: string
}

export const useLoadingModal = () => {
    const modal = useRef<any>()
    const showLoading = useCallback((config?: Config) => {
        modal.current = Modal.info({
            onCancel: () => { alert('') },
            modalRender: () => <Space style={{
                textAlign: 'center'
            }} direction='vertical'>
                <Spin></Spin>
                {
                    config?.message && <Text style={{
                        marginTop: '8vw'
                    }}>
                        {config?.message || 'Loading....'}
                    </Text>
                }
            </Space>,
            style: {
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                overflow: 'hidden',
                outline: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            },
            wrapClassName: "App-modal-loading",
            transitionName: "",
            maskStyle: {
                backgroundColor: 'rgba(255,255,255,0.85)'
            },
            zIndex: 2000
        });

    }, [])
    const hideLoading = useCallback(() => {
        modal.current?.destroy()
    }, [])
    return { showLoading, hideLoading }
}