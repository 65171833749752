import { useWeb3React } from "@web3-react/core"
import { useCallback, useEffect, useRef } from "react"
import { useDispatch } from "react-redux"
import { fetchResort } from "../actions/resort"
import { FETCH_NFT_START, FETCH_NFT_SUCCESS } from "../actions/types"
import { localStorageKey } from "../consumers/heron-nft"
import { chainPublisher } from "../workers/chain"
export let web3ModelGlobal: any = null
export const useOpenWeb3Modal = () => {
  const walletRef = useRef<any>()
  const pollingTask = useRef<any>()
  const { account } = useWeb3React()

  const openWeb3Modal = useCallback(() => {
    walletRef.current.setVisible(true)
  }, [])

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchResort())
    console.log('f5')
    web3ModelGlobal = walletRef
  }, [])
  useEffect(() => {
    if (account) {
      dispatch({
        type: FETCH_NFT_START,
      })
      pollingTask.current = setInterval(async () => {
        const listNft = JSON.parse(await chainPublisher.getState(localStorageKey(account)))
        if (listNft?.length >= 0) {
          dispatch({
            type: FETCH_NFT_SUCCESS,
            payload: listNft
          })
        } else {
          console.info('loading listVoucher....')
        }
      }, 5000);
    } else {
      clearInterval(pollingTask.current)
    }
    return () => {
      clearInterval(pollingTask.current)
    }
  }, [account, dispatch])

  return { openWeb3Modal, walletRef }
}

