import { BrowserRouter, Routes, Route, Link, useLocation } from 'react-router-dom'
import { useEffect, useLayoutEffect } from 'react'

const ScrollToTop = ({ children }: any) => {
    const location = useLocation();
    useEffect(() => {
       setTimeout(() => {
        document.documentElement.scrollTo(0, 0);
       }, 200);
    }, [location.pathname]);
    return null
}


export default ScrollToTop