import { useWeb3React } from '@web3-react/core'
import { Contract, ethers } from 'ethers'
import { useEffect, useState } from 'react'
import { ContractInterface } from 'ethers'

export const useContractSigner = (address: string, Abi: ContractInterface) => {
  const [contract, setContract] = useState<Contract | null>(null)
  const { library } = useWeb3React()
  let contractAddress = ethers.utils.getAddress(address + '')

  useEffect(() => {
    let signer = library && library.getSigner()
    signer && contractAddress && library && setContract(new Contract(contractAddress, Abi, signer))
  }, [library, contractAddress])

  return contract
}
export const useContract = (address: string, Abi: ContractInterface) => {
  const [contract, setContract] = useState<Contract | null>(null)
  const { library } = useWeb3React()
  let contractAddress = ethers.utils.getAddress(address + '')

  useEffect(() => {
    library && contractAddress && library && setContract(new Contract(contractAddress, Abi, library))
  }, [library, contractAddress])

  return contract
}
