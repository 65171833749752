import { CLAIM_EVENT, CLAIM_EVENT_CLEAR, TRANSFER_NFT_SUCCESS } from '../actions/types';

const initialState: {
  data: any[]
} = {
  data: []
}

export default function (state = initialState, action: { type: any; payload: any; }) {
  const { type, payload } = action;
  switch (type) {
    case CLAIM_EVENT:
      return { data: [...state.data, payload] }
    case CLAIM_EVENT_CLEAR:
      return { data: [] }
    case TRANSFER_NFT_SUCCESS:
      return { data: state.data.filter((item: any) => item.tokenId.toNumber() != payload.tokenId) }
    default:
      return state;
  }
}