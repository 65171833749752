import { useWeb3React } from "@web3-react/core";
import { useCallback, useState } from "react";
import { useContractSigner } from "../../../Hook/useContract";
import HeronNft2023Abi from "../../../constants/heronnft2023.abi.json";
import { notification } from "antd";
import { BigNumber } from "ethers";
import { useDispatch } from "react-redux";

export const useClaimNft = (contractAddress: string, listClaimNft: any[], setListClaimNft: React.Dispatch<React.SetStateAction<any[]>>) => {
  const { account, library } = useWeb3React();
  const [loading, setLoading] = useState(false);
  const HeronNft2023ContractSigner = useContractSigner(contractAddress, HeronNft2023Abi);
  const dispatch = useDispatch();
  const claim = useCallback(
    async (tokenId: string) => {
      if (!account || !library || !HeronNft2023ContractSigner) {
        notification.error({
          message: "Please connect to wallet",
        });
        return Promise.reject("Missing account or nftContract");
      }
      try {
        console.log("Claiming NFT #", tokenId);
        setLoading(true);
        setListClaimNft((pre) => [...pre, tokenId]);
        const tx = await HeronNft2023ContractSigner.claim(tokenId);
        await tx.wait(1);
        console.log("Claimed NFT #", tokenId);
        setListClaimNft((pre) => pre.filter((item) => item != tokenId));
        // setLoading(false);
        notification.success({
          message: `Claimed NFT #${tokenId} successfully`,
        });
        dispatch({ type: 'CLAIM_EVENT', payload: {
          tokenId: BigNumber.from(tokenId)
        } })
      } catch (error: any) {
        setLoading(false);
        notification.error({
          message: error?.message || error,
        });
      }
    },
    [account, library, HeronNft2023ContractSigner]
  );
  return { claim, isClaiming: loading };
};
