
import { Resort } from '../Types/Resort';
import { FETCH_RESORT_SUCCESS } from '../actions/types'
const initialState: {
    data: Resort[]
} = {
    data: []
};
export default function (state = initialState, action: { type: any; payload: any; }) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_RESORT_SUCCESS:
            return { data: payload };
        default:
            return state;
    }
}