import Moment from "moment"
import { extendMoment } from "moment-range"
import { REMINDER_MATCH_FUNC } from '../constants/index'
export const moment = extendMoment(Moment as any);

export const getCheckInTimeToDate = (time: any) => {
    return moment(Number(time)).set({ "hour": process.env.REACT_APP_CHECKIN as any, "minute": 0, "second": 0, "millisecond": 0 }).toDate();
};
export const getCheckOutTimeToDate = (time: any) => {
    return moment(Number(time)).set({ "hour": process.env.REACT_APP_CHECKOUT as any, "minute": 0, "second": 0, "millisecond": 0 }).toDate();
};

const _arrayTimes = (startTime: any, endTime: any) => {
    let ret = []
    startTime = moment(startTime)
    endTime = moment(endTime)
    while (startTime.isBefore(endTime)) {
        ret.push(moment(startTime).startOf('days'))
        startTime.add(1, 'day')
    }
    return ret
}
/**
 * 
 * @param {Timeline} timeline 
 * @param {string|boolean} Timeline.reminder
 * @param {Time} Timeline.startTime
 * @param {Time} Timeline.endTime
 * @param {Time} time 
 */
export const isReminder = (timeline: any, time: any) => {
    if (!timeline.reminder) return false
    if (!timeline.reminder) return false

    let times = _arrayTimes(timeline.startTime, timeline.endTime)
    let timeReminder = times.find((t: any) => REMINDER_MATCH_FUNC[timeline.reminder]?.match(t, time))

    return timeReminder
}
