import axios from "axios";
import { useCallback } from "react"
const useFetchLongLink = () => {
    const getLongLink = useCallback(async (shortLink: string) => {
        for (let index = 0; index < 5; index++) {
            try {
                let longLink = await axios.get(`${process.env.REACT_APP_BITLY_ENDPOINT}/api/url/bitlinks${shortLink}`, {
                    headers: {
                        "Authorization": `Bearer ${process.env.REACT_APP_BITLY_TOKEN}`
                    }
                }).then((response) => response?.data?.data?.longUrl)
                return longLink
            } catch (error: any) {
                if (error?.response?.status === 429) {
                    continue
                }
                return Promise.reject("Something error when fetch long url.")
            }
        }
        return Promise.reject("Something error when fetch long url.")
    }, [])

    return { getLongLink }
}
export default useFetchLongLink